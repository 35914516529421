import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { singleMember, memberSpaces, memberInvoice, memberActive } from '../../api/member';
import { DESKIE_API as API } from '../../config';
import copyIcon from "../../Assets/Images/icon/copy-01.svg";
import more from "../../Assets/Images/icon/dots-vertical.svg";
import { Link, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';
import penIcon from "../../Assets/Images/icon/pencil-01.svg";
import alert from "../../Assets/Images/icon/alert.png";
import EditMember from './EditMember';
import memberAvatar from "../../Assets/Images/icon/memberLargeIcon.png";
import spacesIcon from "../../Assets/Images/icon/spaceAvatar.png";
import PhoneInput from 'react-phone-input-2';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { ToastContainer } from 'react-toastify';
import { singleProfile } from '../../api/settings';
import { reminderMember } from './../../api/member';
import { getAgreement } from '../../api/agreement';
import { Tab, Tabs } from 'react-bootstrap';
import { useFetchCurrency } from '../../CommonFunction/Function';
import html2pdf from "html2pdf.js";


const ViewMember = () => {
    const { id } = useParams();
    const [memberDetails, setMemberDetails] = useState<any>({});
    const [spacesList, setSpacesList] = useState<any>([]);
    const [invoiceList, setInvoiceList] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [memberId, setMemberId] = useState("");
    const [updateShow, setUpdateShow] = useState(false);
    const handleUpdateClose = () => setUpdateShow(false);
    const [companyName, setCompanyName] = useState("");
    const [darkLogoImage, setDarkLogoImage] = useState("");
    const [address, setAddress] = useState("");
    const [emailContent, setEmailContent] = useState('')
    const [key, setKey] = useState('notes');
    const { currencyIcon, fetchCurrency } = useFetchCurrency();
    const [content, setContent] = useState('')
    const [signatureImageUrl, setSignatureImageUrl] = useState('')
    const [logoImageUrl, setLogoImageUrl] = useState('')
    const [agreementId, setAgreementId] = useState('')
    const [agreementDate, setAgreementDate] = useState('')

    useEffect(() => {
        getAgreement().then((data) => {
            if (data.data) {
                setContent(data.data.agreement);
                setAgreementId(data.data.id);
                setAgreementDate(data.data.created_at);
            }
        }).catch((err) => { console.log(err) });
    }, [])
    useEffect(() => {
        fetchCurrency();
        if (id) {
            singleMember(id).then((data) => {
                setMemberDetails(data.data && data.data);
            }).catch((err) => { console.log(err) });

            memberSpaces(id).then((data) => {
                setSpacesList(data.spaces && data.spaces);
            }).catch((err) => { console.log(err) })

            memberInvoice(id).then((data) => {
                setInvoiceList(data.invoice && data.invoice);
            }).catch((err) => { console.log(err) });
        }
        getAgreement().then((data) => {
            if (data.data.welcome_email) {
                setEmailContent(data.data.welcome_email)
            }
        }).catch((err) => { console.log(err) });

    }, [count]);
    const getSingleProfile = async () => {
        try {
            const { data } = await singleProfile();
            setCompanyName(data.company_name);
            setDarkLogoImage(data.company_logo_dark);
            setAddress(data.address);
        } catch (err) {
            console.log(err);
        };
    };

    useEffect(() => {
        getSingleProfile();
    }, []);

    // member update view
    const memberUpdate = (memberId: string) => {
        setMemberId(memberId);
        setUpdateShow(true);
    }


    const disableMember = (memberId: string, active: boolean) => {
        let memberInfo = {
            "memberId": memberId,
            "active": active
        }
        memberActive(memberInfo).then(() => {
            showNotifications(TOAST_TYPE.success, "Status updated successfully")
            setCount(count + 1)
        }).catch((err) => { console.log(err) });
    }


    const reminderEmail = () => {
        let memberInfo = {
            firstName: memberDetails.first_name,
            lastName: memberDetails.last_name,
            email: memberDetails.email,
            businessName: memberDetails.business_name,
            businessEmail: memberDetails.business_email,
            notes: memberDetails.notes,
            id: memberDetails.id,
            phoneNumber: memberDetails.phone_number,
            businessPhone: memberDetails.business_phone,
            companyName: companyName,
            darkLogo: darkLogoImage,
            address: address,
            logoImage: `${API}/${encodeURI(darkLogoImage)}`,
            active: 'true',
            emailContent: emailContent
        }
        reminderMember(memberInfo).then(() => {
            showNotifications(TOAST_TYPE.success, "Onboarding reminder sent")
            setCount(count + 1)
        }).catch((err) => { console.log(err) });

    }



    const handleCopyClick = () => {
        navigator.clipboard.writeText(`${window.location.origin}.deskie.com/sign-up?token=${memberDetails.token_email}`);
        showNotifications(TOAST_TYPE.success, "Onboarding copy successfully")
    };


    // pdf download

    function toBase64(url: any, callback: any) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
            var uInt8Array: any = new Uint8Array(this.response);
            var i = uInt8Array.length;
            var binaryString = String.fromCharCode.apply(null, uInt8Array);
            var base64String = window.btoa(binaryString); // Base64 encode
            callback(base64String);
        };
        xhr.send();
    }
    toBase64(`${API}/${memberDetails.signature_image}`, (base64Image: any) => {
        const signatureImageUrl = `data:image/png;base64,${base64Image}`;
        setSignatureImageUrl(signatureImageUrl);
    });
    toBase64(`${API}/${encodeURI(darkLogoImage)}`, (base64Image: any) => {
        const signatureImageUrl = `data:image/png;base64,${base64Image}`;
        setLogoImageUrl(signatureImageUrl);
    });


    const agreementText = () => {
        const element = document.createElement("div");
        element.id = "agreement-content";
        element.style.paddingTop = "30px";
        element.style.paddingLeft = "30px";
        element.style.paddingRight = "30px";
        element.style.paddingBottom = "30px";
        let addressHtml = '';
        if (address) {
            addressHtml = `<p style="font-size: 10px !important; color: black;">Address: ${address}</p>`;
        }
        element.innerHTML = `
         <div style="display: block; margin: 0 auto; text-align: center;">
         <img src="${logoImageUrl}" alt="Signature" style="max-width: 100px; max-height: 60px;" /> ${companyName}
         </div>
    
         ${content}
        
        
        <div class="page-break"></div>

         <div class="" style="margin-top: 30px; text-align: left;">
            <h6 style="font-size: 23px !important; color: #222; font-weight: 800;">User Information:</h6>
            <p style="font-size: 14px !important; color: black; font-weight: 700;">Name: ${memberDetails.first_name} ${memberDetails.last_name}</p>
            <div class="memberInfo" style="font-size: 11px !important; border: 1px solid rgba(234, 236, 240, 1);">
                            <div class="memberInfoBox" style="padding: 12px !important">
                                <h6 style="font-size: 12px !important; color: black;">Phone Number</h6>
                                <p style="font-size: 12px !important; color: black;">${memberDetails.phone_number}</p>
                            </div>
                            <div class="memberInfoBox" style="padding: 12px !important; border-left: 1px solid  rgba(234, 236, 240, 1); border-right: 1px solid  rgba(234, 236, 240, 1)">
                                <h6 style="font-size: 12px !important; color: black;">Email Address</h6>
                                <p style="font-size: 12px !important; color: black;">${memberDetails.email}</p>
                            </div>
                            <div class="memberInfoBox" style="padding: 12px !important">
                                <h6 style="font-size: 12px !important; color: black;">Account Signup Date</h6>
                                <p style="font-size: 12px !important; color: black;">${moment(memberDetails.created_at).format("MMMM DD, YYYY")}</p>
                            </div>
                            <div class="memberInfoBox" style="padding: 12px !important">
                                <h6 style="font-size: 12px !important; color: black;">Business Name</h6>
                                <p style="font-size: 12px !important; color: black;">${memberDetails.business_name}</p>
                            </div>
                            <div class="memberInfoBox" style="padding: 12px !important; border-left: 1px solid  rgba(234, 236, 240, 1); border-right: 1px solid  rgba(234, 236, 240, 1)">
                                  <h6 style="font-size: 12px !important; color: black;">Business Email</h6>
                                <p style="font-size: 12px !important; color: black;">${memberDetails.business_email}</p>
                            </div>
                            <div class="memberInfoBox" style="padding: 12px !important">
                             <h6 style="font-size: 12px !important; color: black;">Business Phone</h6>
                                <p style="font-size: 12px !important; color: black;">${memberDetails.business_phone}</p>
                            </div>
                        </div>

          </div>
      
          <div style="margin-top: 30px; text-align: left;">
            <img src="${signatureImageUrl}" alt="Signature" style="max-width: 200px; display: block;" />
            <p style="margin-top: 10px; font-weight: bold;">${memberDetails.signature}</p>
            <p style="font-size: 10px !important; color: black;">Date Signed: ${moment(memberDetails.created_at).format("MMMM DD, YYYY")}</p>
             <p style="font-size: 10px !important; color: black;">Agreement ID: ${agreementId}</p>
             <p style="font-size: 10px !important; color: black;">Signature ID: ${agreementId}</p>
             ${addressHtml}
            <p style="font-size: 10px !important; color: black;">Agreement Upload Date: ${moment(agreementDate).format("MMMM DD, YYYY")}</p>
          </div>
        `;

        return element;
    };




    const downloadPdf = () => {
        const agreementContent = agreementText();
        const options = {
            margin: 1,
            filename: `${memberDetails.first_name}-${memberDetails.last_name}-Membership-Agreement`,
            html2canvas: {
                scale: 2,
                logging: true
            },
            jsPDF: {
                unit: "mm",
                format: "a4",
                orientation: "portrait",
                font: "OpenSans",
                fontSize: 12,
            },
            pageBreak: { mode: 'avoid-all', before: '.page-break' }
        };

        html2pdf()
            .from(agreementContent)
            .set(options)
            .save()
    };




    return (
        <div id='view-member'>
            <Layout>
                <ToastContainer />
                <div className='mainContent pcMember'>
                    <div className="invoiceHeading">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb m-0 ms-2">
                                <li className="breadcrumb-item"><Link to="/member">Members</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{memberDetails.first_name} {memberDetails.last_name}</li>
                            </ol>
                        </nav>
                    </div>

                    {memberDetails.signature_image && memberDetails.signature ? "" : <div className="incomplete-card">
                        <div className="icon-cnt">
                            <img src={alert} alt="alert" />
                        </div>
                        <div className="content-cnt">
                            <p><b>Onboarding Incomplete</b></p>
                            <p>This member hasn’t finished their account setup.</p>
                            <p className='reminder' onClick={reminderEmail}><b>Send Reminder</b></p>
                            <a className='onBoarding' onClick={handleCopyClick} >Copy Onboarding Url</a>
                        </div>
                    </div>}

                    <div className="membersDetails">
                        <div className="topLine">
                            <div className='tableHeading'>
                                <div className="memberName">
                                    <Link to="/member" className='backDashboard'><FontAwesomeIcon icon={faArrowLeft} /></Link>
                                    {memberDetails.member_image ?
                                        <><img src={`${API}/${memberDetails.member_image}`} alt="avatar" style={{ borderRadius: "50%", objectFit: "cover" }} /></>
                                        : <><img src={memberAvatar} alt="avatar" style={{ borderRadius: "50%" }} /></>
                                    }
                                    <div>
                                        <h6 className='mb-1'>{memberDetails.first_name} {memberDetails.last_name}</h6>
                                        <p>Member</p>
                                    </div>
                                </div>
                            </div>
                            <div className="editBtn">
                                <span className={'invite status ' + (memberDetails.active ? 'active' : 'inactive')}>{memberDetails.active ? 'Active' : 'Inactive'}</span>
                                {memberDetails.signature_image && memberDetails.signature ? <button className='agreement' onClick={downloadPdf}>Agreement</button> : ""}
                                {memberDetails.active ? <button className='disableMember' onClick={() => disableMember(memberDetails.id, memberDetails.active)}>Disable Member</button>
                                    : <button className='activeMember' onClick={() => disableMember(memberDetails.id, memberDetails.active)}>Active Member</button>}
                                <button className='edit py-2' onClick={() => memberUpdate(memberDetails.id)}><img src={penIcon} alt="edit" /> Edit Member</button>

                            </div>
                        </div>
                        <div className="memberInfo">
                            <div className="memberInfoBox">
                                <h6>Phone Number</h6>
                                <PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={memberDetails.phone_number} />
                            </div>
                            <div className="memberInfoBox" style={{ borderLeft: '1px solid  rgba(234, 236, 240, 1)', borderRight: '1px solid  rgba(234, 236, 240, 1)' }}>
                                <h6>Email Address</h6>
                                <p>{memberDetails.email}</p>
                            </div>
                            <div className="memberInfoBox">
                                <h6>Account Signup Date</h6>
                                <p>{moment(memberDetails.created_at).format("MMMM DD, YYYY")}</p>
                            </div>
                            <div className="memberInfoBox">
                                <h6>Business Name</h6>
                                <p>{memberDetails.business_name}</p>
                            </div>
                            <div className="memberInfoBox" style={{ borderLeft: '1px solid  rgba(234, 236, 240, 1)', borderRight: '1px solid  rgba(234, 236, 240, 1)' }}>
                                <h6>Business Phone</h6>
                                <PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={memberDetails.business_phone} />
                            </div>
                            <div className="memberInfoBox">
                                <h6>Business Email</h6>
                                <p>{memberDetails.business_email}</p>
                            </div>
                        </div>
                        <div className="memberInvoice">
                            <div className="invoiceLeft">
                                <div className="memberNotes">
                                    <h6>Notes</h6>
                                    <p className='mb-0'>{memberDetails.notes ? memberDetails.notes : "You haven’t added any notes for this user."}</p>
                                </div>
                                <div className="memberAssign mt-4">
                                    <h6 className='mb-3'>{memberDetails.first_name}’s Active Assignments</h6>
                                    {spacesList && spacesList.length ?
                                        spacesList && spacesList.map((spaces: any) =>
                                            <div className="invoiceHeading">
                                                <div className="invoiceName">
                                                    <p>{spaces.spaces_image ? <img src={`${API}/${spaces.spaces_image}`} alt="member" /> : <img src={spacesIcon} alt="spaces" />} <span>{spaces.spaces_name}</span> </p>
                                                    <div className='deskType'>
                                                        {spaces.spaces_tag === "private" ? <span className='private'>Private Office</span> : ""}
                                                        {spaces.spaces_tag === "dedicated" ? <span className='dedicated'>Dedicated Desk</span> : ""}
                                                        {spaces.spaces_tag === "flex" ? <span className='flex'>Flex</span> : ""}
                                                    </div>
                                                </div>

                                                <div className="invoicePrice billingAction">
                                                    <p>{currencyIcon}{spaces.amount} <span>/mo</span> </p>
                                                    <button className='btn download'><img src={more} alt="download" /></button>
                                                </div>
                                            </div>
                                        ) : <p className='mb-0'>No active assignments.</p>
                                    }
                                </div>
                                <div className="invoiceHistory mt-4">
                                    <div className='mb-3 d-flex justify-content-between'>
                                        <h6 className='mb-0'>{memberDetails.first_name}’s Invoice History</h6>
                                    </div>
                                    {invoiceList && invoiceList.length ?
                                        <div className="invoiceMemberList">
                                            {invoiceList && invoiceList.map((invoice: any) =>
                                                <div className="invoiceBox">
                                                    <div className="invoiceHeading">
                                                        <div className="invoiceName">
                                                            <h6>{invoice.spaces_name ? invoice.spaces_name : "N/A"}</h6>
                                                        </div>
                                                        <div className="invoicePrice billingAction">
                                                            <p>{currencyIcon}{invoice.amount}</p>
                                                            <button className='btn download'><img src={more} alt="download" /></button>
                                                        </div>
                                                    </div>
                                                    <div className="invoiceDetails">
                                                        <div className="assign">
                                                            <h6>Due Date</h6>
                                                            <p>{invoice.invoice_date ? <>{moment(invoice.invoice_date).format("MMMM DD, YYYY")}</> : "N/A"}</p>
                                                        </div>
                                                        <div className="assign">
                                                            <h6>Status</h6>
                                                            <p className='status'>
                                                                {invoice.invoice_status === "paid" ? <span className='paid'>Paid</span>
                                                                    : invoice.invoice_status === "void" ? <span className='unpaid'>Void</span>
                                                                        : <span className='unpaid'>Unpaid</span>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div> : <p className='mb-0'>No invoice history.</p>
                                    }
                                </div>
                            </div>
                            {/* <div className="invoiceRight">
                                <div className="memberBooking">
                                    <h6 className='mb-3'>{memberDetails.first_name}’s Upcoming Bookings</h6>
                                    <p className='mb-0'>No upcoming bookings.</p>
                                    <div className="bookingList">
                                        <img src={`${API}/${memberDetails.member_image}`} alt="member" />
                                        <div className="spacesInfo">
                                            <h6>{memberDetails.member_image}</h6>
                                            <p>asa</p>
                                        </div>
                                    </div>
                                    <div className="bookingList">
                                        <img src={`${API}/${memberDetails.member_image}`} alt="member" />
                                        <div className="spacesInfo">
                                            <h6>{memberDetails.member_image}</h6>
                                            <p>asa</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className='mainContent mobileMember'>
                    <div className="mobileMemberView">
                        {memberDetails.signature_image && memberDetails.signature ? "" : <div className="incomplete-card">
                            <div className="icon-cnt">
                                <img src={alert} alt="alert" />
                            </div>
                            <div className="content-cnt">
                                <p><b>Onboarding Incomplete</b></p>
                                <p>This member hasn’t finished their account setup.</p>
                                <p className='reminder' onClick={reminderEmail}><b>Send Reminder</b></p>
                            </div>
                        </div>}
                        <div className="mobileMemberInfo">
                            <div className='tableHeading'>
                                <div className="memberName">
                                    <span className={'invite status ' + (memberDetails.active ? 'active' : 'inactive')}>{memberDetails.active ? 'Active' : 'Inactive'}</span>
                                    {memberDetails.member_image ?
                                        <><img src={`${API}/${memberDetails.member_image}`} alt="avatar" style={{ borderRadius: "50%", objectFit: "cover" }} /></>
                                        : <><img src={memberAvatar} alt="avatar" style={{ borderRadius: "50%" }} /></>
                                    }
                                    <div>
                                        <h6 className='mb-1'>{memberDetails.first_name} {memberDetails.last_name}</h6>
                                        <p className='text-center'>Member</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mobileEditBtn">
                                <button className='edit py-2' onClick={() => memberUpdate(memberDetails.id)}><img src={penIcon} alt="edit" /> Edit Member</button>
                                {memberDetails.signature_image && memberDetails.signature ? <button className='agreement' onClick={downloadPdf}>Agreement</button> : ""}
                                {memberDetails.active ? <button className='disableMember' onClick={() => disableMember(memberDetails.id, memberDetails.active)}>Disable Member</button>
                                    : <button className='activeMember' onClick={() => disableMember(memberDetails.id, memberDetails.active)}>Active Member</button>}
                            </div>
                            <div className="mobileDetails">
                                <div className="memberInfoBox">
                                    <h6>Phone Number</h6>
                                    <PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={memberDetails.phone_number} />
                                </div>
                                <div className="memberInfoBox" style={{ borderLeft: '1px solid  rgba(234, 236, 240, 1)', borderRight: '1px solid  rgba(234, 236, 240, 1)' }}>
                                    <h6>Email Address</h6>
                                    <p>{memberDetails?.email && memberDetails?.email.length > 25 ? memberDetails.email.slice(0, 25) + '...' : memberDetails.email}</p>
                                </div>
                                <div className="memberInfoBox">
                                    <h6>Account Signup Date</h6>
                                    <p>{moment(memberDetails.created_at).format("MMMM DD, YYYY")}</p>
                                </div>
                                <div className="memberInfoBox">
                                    <h6>Business Name</h6>
                                    <p>{memberDetails.business_name}</p>
                                </div>
                                <div className="memberInfoBox" style={{ borderLeft: '1px solid  rgba(234, 236, 240, 1)', borderRight: '1px solid  rgba(234, 236, 240, 1)' }}>
                                    <h6>Business Phone</h6>
                                    <PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={memberDetails.business_phone} />
                                </div>
                                <div className="memberInfoBox">
                                    <h6>Business Email</h6>
                                    <p>{memberDetails.business_email}</p>
                                </div>
                            </div>
                            <div className="mobileMemberTab">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k: any) => setKey(k)}
                                    className="mb-3"
                                >
                                    <Tab eventKey="notes" title="Notes">
                                        <div className="tabContent">
                                            <div className="memberNotes">
                                                <h6>Notes</h6>
                                                <p className='mb-0'>{memberDetails.notes ? memberDetails.notes : "You haven’t added any notes for this user."}</p>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="assignments" title="Assignments">
                                        <div className="AssignTabContent">
                                            {spacesList && spacesList.length ?
                                                spacesList && spacesList.map((spaces: any) =>
                                                    <div className="mobileAssignment">
                                                        <div className="assignName">
                                                            <p>{spaces.spaces_image ? <img src={`${API}/${spaces.spaces_image}`} alt="member" /> : <img src={spacesIcon} alt="spaces" />} <span>{spaces.spaces_name}</span></p>
                                                            <button className='btn download'><img src={more} alt="download" /></button>
                                                        </div>

                                                        <div className="invoicePrice billingAction">
                                                            <div className='deskType'>
                                                                {spaces.spaces_tag === "private" ? <span className='private'>Private Office</span> : ""}
                                                                {spaces.spaces_tag === "dedicated" ? <span className='dedicated'>Dedicated Desk</span> : ""}
                                                                {spaces.spaces_tag === "flex" ? <span className='flex'>Flex</span> : ""}
                                                            </div>
                                                            <p>{currencyIcon}{spaces.amount} <span>/mo</span> </p>
                                                        </div>
                                                    </div>
                                                ) : <p className='mb-0'>No active assignments.</p>
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="invoice" title="Invoice">
                                        <div className="tabContent">
                                            {invoiceList && invoiceList.length ?
                                                <div className="invoiceMemberList">
                                                    {invoiceList && invoiceList.map((invoice: any) =>
                                                        <div className="mobileInvoice">
                                                            <div className="mobileInvoiceHeading">
                                                                <Link to={`/my-invoice-details/${invoice.id}`}>#INV{invoice?.invoice_id && invoice?.invoice_id.toString().padStart(3, "0")}</Link>
                                                                <p className='invoiceRate'>{invoice.amount ? <>{currencyIcon} {invoice.amount}</> : "N/A"} <button className='btn'><img src={more} alt="dots" /></button></p>
                                                            </div>
                                                            <div className="mobileInvoiceInfo">
                                                                <div>
                                                                    <p>Assignment</p>
                                                                    <p><img className='spaceImg' src={`${API}/${invoice.spaces_image}`} alt="space" /> {invoice.spaces_name ? invoice.spaces_name : "N/A"}</p>
                                                                </div>
                                                                <div>
                                                                    <p>Due Date</p>
                                                                    <p className="date">{moment(invoice.created_at).format("MMMM DD, YYYY")}</p>
                                                                </div>
                                                                <div>
                                                                    <p>Status</p>
                                                                    {invoice.status ? (
                                                                        <p className='status'>
                                                                            <span
                                                                                className={
                                                                                    invoice.status === "paid"
                                                                                        ? "paid"
                                                                                        : invoice.status === "unpaid"
                                                                                            ? "unpaid"
                                                                                            : "void"
                                                                                }
                                                                                style={{
                                                                                    textTransform: "capitalize",
                                                                                }}
                                                                            >
                                                                                {invoice.status}
                                                                            </span>
                                                                        </p>
                                                                    ) : (
                                                                        <>
                                                                            {invoice.renewal_frequency === "resource" ? (
                                                                                <p className='status'>
                                                                                    {parseFloat(invoice.total_payment_amount) >=
                                                                                        parseFloat(invoice.amount) ? (
                                                                                        <span className='paid'>Paid</span>
                                                                                    ) : (
                                                                                        <span className='unpaid'>Unpaid</span>
                                                                                    )}
                                                                                </p>
                                                                            ) : invoice.renewal_frequency === "today" ? (
                                                                                <p className='status'><span className='unpaid'>Unpaid</span></p>
                                                                            ) : (
                                                                                <p className='status'>
                                                                                    {invoice.total_amount === 0 ? (
                                                                                        <span className='draft'>Upcoming</span>
                                                                                    ) : parseFloat(invoice.total_payment_amount) >=
                                                                                        parseFloat(invoice.total_amount) ? (
                                                                                        <span className='paid'>Paid</span>
                                                                                    ) : (<span className='draft'>Upcoming</span>)}
                                                                                </p>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    )}
                                                </div> : <p className='mb-0'>No invoice history.</p>
                                            }
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

                <EditMember memberId={memberId} updateShow={updateShow} setUpdateShow={setUpdateShow} handleUpdateClose={handleUpdateClose} />
            </Layout>
        </div>
    )
}

export default ViewMember