import React, { useState, useEffect } from "react";
import Layout from "../../Component/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import filter from "../../Assets/Images/icon/filter-lines.svg";
import { Container, Dropdown, Row, Table } from "react-bootstrap";
import Pagination from "../../Component/Pagination/Pagination";
import AddResources from "../../Component/AddResources/AddResources";
import { adminResourceList, deleteResourceBooking, resourceList } from "../../api/resource";
import { DESKIE_API as API } from "../../config";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import editPen from "../../Assets/Images/icon/edit-01.svg";
import ResourceBooking from "../../Component/ResourceBooking/ResourceBooking";
import calenderIcon from "../../Assets/Images/icon/calendar-check-01.svg";
import "./Resources.css";
import {
  formatResourceDate,
  truncateString,
} from "../../CommonFunction/Function";
import EditResource from "../../Component/ViewResource/EditResource";
import UpcomingMobile from "../../Component/MobileResource/UpcomingMobile";
import ResourceMobile from "../../Component/MobileResource/ResourceMobile";
import ConfirmationModal from "../../Component/ConfirmationModal/ConfirmationModal";
import { showNotifications, TOAST_TYPE } from "../../CommonFunction/toaster";

const isMobile = window.innerWidth <= 480;

const Resources = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [upcomingShow, setUpcomingShow] = useState(false);
  const handleUpcomingClose = () => setUpcomingShow(false);
  const handleUpcomingShow = () => setUpcomingShow(true);
  const [resourceShow, setResourceShow] = useState(false);
  const handleResourceClose = () => setResourceShow(false);
  const handleResourceShow = () => setResourceShow(true);
  const [resourceDetails, setResourceDetails] = useState<any>({});
  const [paymentShow, setPaymentShow] = useState(false);
  const handlePaymentClose = () => setPaymentShow(false);
  const handlePaymentShow = (resource: any) => {
    setPaymentShow(true);
    setResourceDetails(resource);
  };
  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);
  const [editInfo, setEditInfo] = useState({});
  const [resourceLists, setResourceLists] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const [result, setResult] = useState<string[]>([]);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [limitValue, setLimitValue] = useState<number>(0);
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>();
  const [bookingResult, setBookingResult] = useState<any[]>([]);
  const [filterTag, setFilterTag] = useState("");
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [deleteResourceBookingID, setDeleteResourceBookingID] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAllUpcomingBookings, setShowAllUpcomingBookings] = useState(
    !isMobile
  );
  const [showAllResources, setShowAllResources] = useState(!isMobile);

  useEffect(() => {
    resourceList(limit, page, filterTag)
      .then((data) => {
        setResourceLists(data.resource);
        setTotalValue(data.total);
        setLimitValue(data.limit);
        setPageValue(data.page);
      })
      .catch((err) => {
        console.log(err);
      });

    adminResourceList()
      .then((data) => {
        setBookingResult(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [show, limit, page, editShow, filterTag]);

  const nextPage = () => {
    setResult([]);
    setPage(page + 1);
    setNextButton(true);
  };

  const prevPage = () => {
    setResult([]);
    setPage(page - 1);
  };

  const viewResource = (resourceId: string) => {
    return navigate(`${resourceId}`);
  };

  const editResource = (resourceInfo: string) => {
    setEditShow(true);
    setEditInfo(resourceInfo);
  };

  const handleSort = (columnName: string) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  const sortedResource = [...resourceLists].sort((a: any, b: any) => {
    if (!sortBy) return 0;
    const aValue = a[sortBy];
    const bValue = b[sortBy];

    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue) * (sortOrder === "asc" ? 1 : -1);
    }
    if (typeof aValue === "number" && typeof bValue === "number") {
      return (aValue - bValue) * (sortOrder === "asc" ? 1 : -1);
    }
    return 0;
  });


  const handleResourceBookingDelete = async () => {
    try {
      setShowConfirmationModal(false);
      if (!deleteResourceBookingID) {
        return;
      };
      const res = await deleteResourceBooking(deleteResourceBookingID);
      showNotifications(TOAST_TYPE.success, res.message);
      const filteredResourceBookings = bookingResult.filter((booking) => booking.id !== deleteResourceBookingID);
      setBookingResult(filteredResourceBookings);
    } catch { }
  };

  // const upcomingBookings = showAllUpcomingBookings
  //   ? bookingResult
  //   : [bookingResult[0] || {}];

  // const resources = showAllResources
  //   ? sortedResource
  //   : [sortedResource[0] || {}];

  return (
    <Layout>
      <div className='mainContent' id='resources'>
        <div className='resourcesPage'>
          <div className='memberBox'>
            <div className='topLine'>
              <div className='tableHeading'>
                <h6>Available Resources</h6>
              </div>
              <div className='memberSearch'>
                <div className='filterDropdown taskDropdown'>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <button className='filterBtn'>
                        <img className='mr-2' src={filter} alt='filter' />
                        {filterTag === "all"
                          ? "My Types"
                          : filterTag === "workspace"
                            ? "Workspace"
                            : filterTag === "meeting"
                              ? "Meeting Spaces"
                              : filterTag === "equipment"
                                ? "Equipment"
                                : filterTag === "other"
                                  ? "Other"
                                  : "Filters"}
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setFilterTag("all")}>
                        All Types
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterTag("workspace")}>
                        Workspace
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterTag("meeting")}>
                        Meeting Space
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterTag("equipment")}>
                        Equipment
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setFilterTag("other")}>
                        Other
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {pathParts[1] === "resources" ? (
                  <button onClick={handleShow}>
                    <FontAwesomeIcon icon={faPlus} /> Add Resource
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className='spaceList'>
              <div className='pcMobileResource w-100'>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>
                        <label className='tableCheckBox'>
                          <div className='contactCheck'>
                            <input type='checkbox' name='agreement' />
                            <span className='checkmark'></span>
                          </div>
                        </label>
                      </th>
                      <th></th>
                      <th
                        className='sortArrow'
                        onClick={() => handleSort("name")}
                      >
                        Name{" "}
                        {sortBy === "name" ? (
                          <>
                            {sortOrder === "asc" ? (
                              <FontAwesomeIcon icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon icon={faArrowDown} />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </th>
                      <th
                        className='sortArrow'
                        onClick={() => handleSort("type")}
                      >
                        Type{" "}
                        {sortBy === "type" ? (
                          <>
                            {sortOrder === "asc" ? (
                              <FontAwesomeIcon icon={faArrowUp} />
                            ) : (
                              <FontAwesomeIcon icon={faArrowDown} />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedResource?.length > 0 &&
                      sortedResource.map((data: any, index) => (
                        <tr key={`refer` + index}>
                          <td>
                            <label className='tableCheckBox'>
                              <div className='contactCheck'>
                                <input type='checkbox' name='agreement' />
                                <span className='checkmark'></span>
                              </div>
                            </label>
                          </td>
                          <td>
                            <div
                              className='tableImage justify-content-center'
                              style={{ cursor: "pointer" }}
                              onClick={() => viewResource(data.id)}
                            >
                              {data.image ? (
                                <img
                                  src={`${API}/${data.image}`}
                                  alt='avatar'
                                  style={{
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <img
                                  src={spaceAvatar}
                                  alt='avatar'
                                  style={{ borderRadius: "50%" }}
                                />
                              )}{" "}
                            </div>
                          </td>
                          <td className='tableLink'>
                            <Link to={`${data.id}`}>
                              {truncateString(data.name, 15)}
                            </Link>
                          </td>
                          <td className='resourceType'>
                            {data.type === "meeting" ? (
                              <span className='meeting'>Meeting Space</span>
                            ) : (
                              ""
                            )}
                            {data.type === "equipment" ? (
                              <span className='equipment'>Equipment</span>
                            ) : (
                              ""
                            )}
                            {data.type === "workspace" ? (
                              <span className='workspace'>Workspace</span>
                            ) : (
                              ""
                            )}
                            {data.type === "other" ? (
                              <span className='other'>Other</span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className='tableAction'>
                            <button
                              className='btn view'
                              onClick={() => viewResource(data.id)}
                            >
                              <FontAwesomeIcon icon={faEye as any} />
                            </button>
                            {window.location.pathname.includes(
                              "my-resources"
                            ) ? (
                              ""
                            ) : (
                              <button
                                className='btn edit'
                                onClick={() => editResource(data)}
                              >
                                <img src={editPen} alt='edit' />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
              <div className='mobileAllResource'>
                {sortedResource?.length > 0 &&
                  sortedResource.map((data: any, i) => {
                    return (
                      <div
                        key={`resource` + i}
                        className='bookingPerson'
                        style={{
                          flexDirection: "column",
                          alignItems: "start",
                          gap: 16,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className='tableImage justify-content-center'
                            style={{ cursor: "pointer" }}
                            onClick={() => viewResource(data.id)}
                          >
                            {data.image ? (
                              <img
                                src={`${API}/${data.image}`}
                                alt='avatar'
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={spaceAvatar}
                                alt='avatar'
                                style={{ borderRadius: "50%" }}
                              />
                            )}{" "}
                          </div>
                          <div className='tableLink'>
                            <Link to={`${data.id}`}>
                              {truncateString(data.name, 15)}
                            </Link>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className='resourceType rscT'>
                            {data.type === "meeting" ? (
                              <span className='meeting'>Meeting Space</span>
                            ) : (
                              ""
                            )}
                            {data.type === "equipment" ? (
                              <span className='equipment'>Equipment</span>
                            ) : (
                              ""
                            )}
                            {data.type === "workspace" ? (
                              <span className='workspace'>Workspace</span>
                            ) : (
                              ""
                            )}
                            {data.type === "other" ? (
                              <span className='other'>Other</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className='editBtn serc'>
                            <button
                              style={{
                                width: "100%",
                                height: "100%",
                                paddingTop: "4px",
                                paddingBottom: "4px",
                              }}
                              onClick={() => handlePaymentShow(data)}
                            >
                              Reserve
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {sortedResource.length > 10 && (
                <Pagination
                  page={page}
                  paginationTitle='items'
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                  prevButton={prevButton}
                  nextButton={nextButton}
                  pageValue={pageValue}
                  totalValue={totalValue}
                  prevPage={prevPage}
                  nextPage={nextPage}
                  allRequestList={resourceLists}
                />
              )}
            </div>
          </div>

          <div className='upcomingListResources'>
            <div className='bookingHeading'>
              <h6>
                <img src={calenderIcon} alt='edit' /> Upcoming Bookings
              </h6>
              {/* {bookingResult && isMobile && (
                <p style={{ color: "#6366F1", cursor: "pointer"}} onClick={() => handleUpcomingShow()}>View All</p>
              )} */}
            </div>
            {bookingResult?.length > 0 &&
              bookingResult.map((resource, i) => (
                <div key={i} className='bookingPerson'>
                  <img src={`${API}/${resource.resource_image}`} alt='edit' />
                  <div>
                    <p>
                      {truncateString(resource.resource_name, 15)}{" "}
                      {/* <span>({truncate(resource.creator_name)})</span>{" "} */}
                    </p>
                    <span>
                      {formatResourceDate(resource.book_date)},{" "}
                      {resource.start_time}
                      {/* - {resource.end_time} */}
                    </span>
                  </div>
                  <button onClick={() => viewResource(resource.resource_id)}>
                    <FontAwesomeIcon icon={faEye as any} />
                  </button>
                  <button
                    className='btn edit'
                    onClick={() => {
                      setDeleteResourceBookingID(resource.id);
                      setShowConfirmationModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} color='#f33' />
                  </button>
                </div>
              ))}

            {bookingResult.length > 10 && (
              <Pagination
                page={page}
                paginationTitle='items'
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                prevButton={prevButton}
                nextButton={nextButton}
                pageValue={pageValue}
                totalValue={totalValue}
                prevPage={prevPage}
                nextPage={nextPage}
                allRequestList={bookingResult}
                margin='mt-0'
                padding='pb-0'
                type='upcoming'
              />
            )}
          </div>
        </div>

        <AddResources show={show} setShow={setShow} handleClose={handleClose} />
        <ResourceBooking
          resourceDetails={resourceDetails}
          paymentShow={paymentShow}
          setPaymentShow={setPaymentShow}
          handlePaymentClose={handlePaymentClose}
        />
        <EditResource
          editInfo={editInfo}
          editShow={editShow}
          setEditShow={setEditShow}
          handleEditClose={handleEditClose}
        />
        <UpcomingMobile
          show={upcomingShow}
          setShow={setUpcomingShow}
          handleClose={handleUpcomingClose}
          upcomingBookings={bookingResult}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          prevButton={prevButton}
          nextButton={nextButton}
          pageValue={pageValue}
          totalValue={totalValue}
          prevPage={prevPage}
          nextPage={nextPage}
        />
        <ResourceMobile
          handlePaymentShow={handlePaymentShow}
          show={resourceShow}
          setShow={setResourceShow}
          handleClose={handleResourceClose}
          upcomingBookings={sortedResource}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          prevButton={prevButton}
          nextButton={nextButton}
          pageValue={pageValue}
          totalValue={totalValue}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </div>
      <ConfirmationModal
        ConfirmationShow={showConfirmationModal}
        handleConfirmationClose={() => setShowConfirmationModal(false)}
        afterConfirmationApi={handleResourceBookingDelete}
      />
    </Layout>
  );
};

export default Resources;
