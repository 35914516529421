import React, { ReactNode, useEffect, useState } from "react";
import Header from "../LeftSide/Header";
import LeftSide from "../LeftSide/LeftSide";
import { getCompanyProfile } from "../../api/companyProfile";

interface Props {
  children?: ReactNode;
};

const Layout = ({ children, ...props }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const handleValueChange = (value: any) => {
    setCollapsed(!collapsed);
  };
  const [plan, setPlan] = useState<string>('core');

  const fetchCompanyProfile = async () => {
    try {
      const res = await getCompanyProfile();
      setPlan(res.plan);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.innerWidth <= 1000) {
      setCollapsed(true);
    }
  }, []);

  useEffect(() => {
    fetchCompanyProfile();
  }, []);

  return (
    <>
      <div
        className='d-flex'
        style={{ minHeight: "100vh", overflow: "hidden" }}
      >
        <div className='mobileSidebar'>
          <LeftSide collapsed={collapsed} setCollapsed={setCollapsed} plan={plan} />
        </div>
        <div style={{ width: "100%" }}>
          <Header onValueChange={handleValueChange} />
          <div {...props}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
