import React, { useState, useEffect } from "react";
import {
  faArrowRight,
  faChevronDown,
  faArrowLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Dropdown } from "react-bootstrap";
import calenderIcon from "../../Assets/Images/icon/calendar.svg";
import clockIcon from "../../Assets/Images/icon/clock.svg";
import calenderBlue from "../../Assets/Images/icon/clockBlue.svg";
import clockDark from "../../Assets/Images/icon/clockDark.svg";
import {
  format,
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
} from "date-fns";
import { formatResourceDate } from "../../CommonFunction/Function";
import {
  publicResourceBookedTimeSlots,
  resourceBookTime,
} from "../../api/resource";
import { DESKIE_API as API } from "../../config";
import memberIcon from "../../Assets/Images/icon/memberAvatar.svg";
import { allMemberList } from "../../api/member";
import { isAuthenticate } from "../../api/auth";

interface Member {
  id: string;
  email: string;
  image: string;
  first_name: string;
  last_name: string;
  member_image: string;
}

interface tabMemberProps {
  tabChoose: (tab: string, select: string) => void;
  startTime: string;
  endTime: string;
  selectedDate: string;
  setStartTime: any;
  setEndTime: any;
  setSelectedDate: any;
  resourceDetail: any;
  selectedMember: Member;
  setSelectedMember: React.Dispatch<React.SetStateAction<Member>>;
  isPublic?: boolean;
}
const ResourceSchedule = ({
  resourceDetail,
  setStartTime,
  setEndTime,
  setSelectedDate,
  startTime,
  endTime,
  selectedDate,
  tabChoose,
  selectedMember,
  setSelectedMember,
  isPublic = false,
}: tabMemberProps) => {

  const [isActive, setIsActive] = useState(false);
  const [member, setMember] = useState([]);
  const [searchMembers, setSearchMembers] = useState("");
  const [memberError, setMemberError] = useState(false);
  const [bookingTime, setBookingTime] = useState([]);
  const [booked, setBooked] = useState<any>([]);
  const [isBooked, setIsBooked] = useState(false);
  const [timeError, setTimeError] = useState("");


  const [currentDate, setCurrentDate] = useState<string>("");
  const { user } = isAuthenticate();

  useEffect(() => {
    if (!isPublic) {
      allMemberList().then((data) => {
        setMember(data.members);
      })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const findTimeValues = (startTime: string, endTime: string, booked: any) => {
    const start = timeOptions.find(option => option.label === startTime)?.value;
    const end = timeOptions.find(option => option.label === endTime)?.value;

    if (start == null || end == null) {
      return { series: [], conflict: false }; // Return empty if times are not valid
    }

    // Generate the series
    const series: any = [];
    for (let i = start; i <= end; i++) {
      series.push(i);
    }

    // Check if any booked values exist in the series
    const conflict = booked.some((value: any) => series.includes(value));

    return { series, conflict };
  };

  const scheduleFunction = () => {
    if(startTime === "Choose"){
      setTimeError("Please select start time.");
      return;
    };
    if(endTime === "Choose"){
      setTimeError("Please select end time.");
      return;
    };

    if(startTime === endTime) {
      setTimeError("Start Time and End Time can not be same.");
      return;
    }

    const bookedResults = findTimeValues(startTime, endTime, booked);
    if (bookedResults.series.length > 0 && bookedResults.conflict === true) {
      setIsBooked(true)
    }
    else {
      setIsBooked(false)
      if (isPublic) {
        if (resourceDetail.public_rate === "0") {
          tabChoose("done", "schedule");
        } else {
          tabChoose("billing", "schedule");
        }
      } else {
        if (!selectedMember.id && user.role === "admin") {
          setMemberError(true);
          return;
        }

        if (resourceDetail.member_rate === "0") {
          tabChoose("done", "schedule");
        } else {
          tabChoose("billing", "schedule");
        }
      }
    }
  };

  const backDetails = () => {
    tabChoose("", "");
  };



  useEffect(() => {
    const today = new Date();
    const formattedToday = format(today, "yyyy-MM-dd");
    setCurrentDate(formattedToday);
    const highlightToday = () => {
      const todayElem = document.querySelector(
        `[data-date='${formattedToday}']`
      );
      if (todayElem) {
        todayElem.classList.add("selected-date");
      }
    };
    setTimeout(highlightToday, 100);
  }, []);

  const handleDateClick = (info: any) => {
    // Remove the highlight from the previously selected date
    const prevSelected = document.querySelector(
      ".fc-daygrid-day.selected-date"
    );
    if (prevSelected) {
      prevSelected.classList.remove("selected-date");
      prevSelected.classList.remove("fc-day-today");
    }

    // Add the highlight to the newly selected date
    const newElem = document.querySelector(`[data-date='${info.dateStr}']`);
    if (newElem) {
      newElem.classList.add("selected-date");
    }

    // Update the selected date
    const nowDate = new Date();
    const combinedDate = setMilliseconds(
      setSeconds(
        setMinutes(
          setHours(info.date, nowDate.getHours()),
          nowDate.getMinutes()
        ),
        nowDate.getSeconds()
      ),
      nowDate.getMilliseconds()
    );
    const formattedDate = format(combinedDate, "yyyy-MM-dd HH:mm:ss.SSS");
    setSelectedDate(formattedDate);
  };

  function getBookingTimes(bookingTime: any, selectedDated: any) {
    const selectedDateObj = new Date(selectedDated);
    const selectedDateStr = selectedDateObj.toISOString().split("T")[0];
    let availableTimes = [];
    for (const booking of bookingTime) {
      const bookDateStr = new Date(booking.book_date)
        .toISOString()
        .split("T")[0];
      if (bookDateStr === selectedDateStr) {
        const startTimeValue = timeOptions.find(
          (option) => option.label === booking.start_time
        )?.value;
        const endTimeValue = timeOptions.find(
          (option) => option.label === booking.end_time
        )?.value;
        if (startTimeValue && endTimeValue) {
          if (startTimeValue <= endTimeValue) {
            for (let i = startTimeValue; i <= endTimeValue; i++) {
              availableTimes.push(i);
            }
          } else {
            for (let i = startTimeValue; i <= 24; i++) {
              availableTimes.push(i);
            }
            for (let i = 1; i <= endTimeValue; i++) {
              availableTimes.push(i);
            }
          }
        }
      }
    }
    availableTimes = Array.from(new Set(availableTimes)).sort((a, b) => a - b);
    return availableTimes.length > 0 ? availableTimes : null;
  }

  useEffect(() => {
    const nowDate = new Date();
    const formattedDate = format(nowDate, "yyyy-MM-dd HH:mm:ss.SSS");
    setSelectedDate(formattedDate);

    if (isPublic) {
      publicResourceBookedTimeSlots(resourceDetail.id).then((data) => {
        setBookingTime(data.data || []);
      })
        .catch((err) => {
          console.log(err);
        });
    } else {
      resourceBookTime(resourceDetail.id).then((data) => {
        setBookingTime(data.data || []);
      })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    const availableTimes = getBookingTimes(bookingTime, selectedDate);
    if (availableTimes) {
      setBooked(availableTimes);
    } else {
      setBooked([]);
    }
  }, [selectedDate, bookingTime]);

  const timeOptions = [
    { value: 1, label: "1 AM" },
    { value: 2, label: "2 AM" },
    { value: 3, label: "3 AM" },
    { value: 4, label: "4 AM" },
    { value: 5, label: "5 AM" },
    { value: 6, label: "6 AM" },
    { value: 7, label: "7 AM" },
    { value: 8, label: "8 AM" },
    { value: 9, label: "9 AM" },
    { value: 10, label: "10 AM" },
    { value: 11, label: "11 AM" },
    { value: 12, label: "12 PM" },
    { value: 13, label: "1 PM" },
    { value: 14, label: "2 PM" },
    { value: 15, label: "3 PM" },
    { value: 16, label: "4 PM" },
    { value: 17, label: "5 PM" },
    { value: 18, label: "6 PM" },
    { value: 19, label: "7 PM" },
    { value: 20, label: "8 PM" },
    { value: 21, label: "9 PM" },
    { value: 22, label: "10 PM" },
    { value: 23, label: "11 PM" },
    { value: 24, label: "12 AM" },
  ];

  const handleStartSelect = (eventKey: any) => {
    setStartTime(eventKey);
    setTimeError("");
  };

  const handleEndSelect = (eventKey: any) => {
    setEndTime(eventKey);
    setTimeError("");
  };

  const handleMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMembers(e.target.value);
  };

  const filteredMembers = member?.filter(
    (member: any) =>
      member.first_name.toLowerCase().includes(searchMembers.toLowerCase()) ||
      member.last_name.toLowerCase().includes(searchMembers.toLowerCase()) ||
      member.email.toLowerCase().includes(searchMembers.toLowerCase())
  );


  return (
    <div>
      {user?.role === "admin" && !isPublic && (
        <>
          <div className='memberInfos assignBox'>
            <div className='dropdown'>
              <div
                onClick={(e) => {
                  setIsActive(!isActive);
                }}
                className='dropdown-btn'
              >
                <div className='d-flex tableImage'>
                  {selectedMember.email.length ? (
                    <>
                      {selectedMember.member_image ? (
                        <img
                          src={`${API}/${selectedMember.member_image}`}
                          alt='avatar'
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <img
                          className='default'
                          src={memberIcon}
                          alt='avatar'
                        />
                      )}
                      <div>
                        <p>
                          {selectedMember.first_name.length + selectedMember.last_name.length > 20
                            ? (selectedMember.first_name + ' ' + selectedMember.last_name).substring(0, 20) + "..."
                            : selectedMember.first_name + ' ' + selectedMember.last_name}
                        </p>
                        <span>
                          {selectedMember.email.length > 20
                            ? selectedMember.email.substring(0, 20) + "..."
                            : selectedMember.email}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <p>Select Member</p>
                      </div>
                    </>
                  )}
                </div>
                <span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </span>
              </div>
              <div
                className='dropdown-content'
                style={{ display: isActive ? "block" : "none" }}
              >
                <div className='assignInput px-2'>
                  <FontAwesomeIcon icon={faSearch} />
                  <input
                    type='text'
                    placeholder='Search member'
                    onChange={handleMemberChange}
                    className='form-control'
                  />
                </div>
                <div className='member-container'>
                  {filteredMembers.map((data: any, index: number) => (
                    <div
                      key={`assignMember` + index}
                      onClick={(e) => {
                        setIsActive(!isActive);
                        setSelectedMember(data);
                        setMemberError(false);
                      }}
                      className='item tableImage my-2'
                    >
                      {data.member_image ? (
                        <img
                          src={`${API}/${data.member_image}`}
                          alt='avatar'
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <img
                          className='default'
                          src={memberIcon}
                          alt='avatar'
                        />
                      )}
                      <p>{`${(
                        data.first_name +
                        " " +
                        data.last_name
                      ).slice(0, 17)}${(data.first_name + " " + data.last_name)
                        .length > 17
                        ? "..."
                        : ""
                        }`}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            {memberError && <p className="text-danger mt-2">Please select a member</p>}
          </div>
        </>
      )}

      <div className='paymentDetails'>
        <div className='detailsHeading'>
          <h6>
            <img src={calenderBlue} alt='calender' /> Scheduling
          </h6>
        </div>
        <div className='resourceSchedule'>
          <div className='leftSchedule'>
            <div className=' monthView'>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView='dayGridMonth'
                weekends={true}
                headerToolbar={{
                  left: "",
                  center: "prev,title,next",
                  right: "",
                }}
                dateClick={handleDateClick}
              />
            </div>
            <div className='timeChoose mt-4 pt-2'>
              <h6 className='my-2'>Start & End Time* {isBooked ? <span className="text-danger">(Please choose free times)</span> : ""} </h6>
              <div className='timeDropdown'>
                <Dropdown onSelect={handleStartSelect}>
                  <Dropdown.Toggle>
                    <button className='filterBtn'>
                      {startTime} <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {timeOptions.map((option) => (
                      <Dropdown.Item
                        eventKey={option.label}
                        key={option.value}
                        disabled={booked.includes(option.value)}
                      >
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
                <Dropdown onSelect={handleEndSelect}>
                  <Dropdown.Toggle>
                    <button className='filterBtn'>
                      {endTime} <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {timeOptions.map((option) => (
                      <Dropdown.Item
                        eventKey={option.label}
                        key={option.value}
                        disabled={booked.includes(option.value)}
                      >
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {timeError && (
                  <p className="mt-2 text-sm text-danger">{timeError}</p>
                )}
              </div>
            </div>
          </div>
          <div className='vertical-bar'></div>
          <div className='rightSchedule'>
            <div className='chooseTime'>
              <div>
                <img src={calenderIcon} alt='calender' />
                {selectedDate.length ? (
                  <p className='mb-0 mt-2'>
                    {formatResourceDate(selectedDate)}
                  </p>
                ) : (
                  <p className='mb-0 mt-2'>Choose</p>
                )}
              </div>
              <div>
                <img src={clockDark} alt='calender' />
                <p className='mb-0 mt-2'>
                  {startTime} - {endTime}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='resourcesBtn'>
        <button className='cancel' onClick={backDetails}>
          <FontAwesomeIcon className='mr-2' icon={faArrowLeft} /> Back
        </button>
        <button className='continue' onClick={scheduleFunction}>
          Continue <FontAwesomeIcon className='ml-2' icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default ResourceSchedule;
