import { Card, Table } from "react-bootstrap";
import Layout from "../../Component/Layout/Layout";
import { useEffect, useState } from "react";
import { getBookingList } from "../../api/bookings";
import Pagination from "../../Component/Pagination/Pagination";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useFetchCurrency } from "../../CommonFunction/Function";

const ITEMS_PER_PAGE = 10;

type BookingsData = {
  name: string;
  amount: number;
  invoice_id: string;
  schedule: string;
  // schedule: {
  //   book_date: string;
  //   start_time: string;
  //   end_time: string;
  // };
}[];

const Bookings = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [bookings, setBookings] = useState<BookingsData>([]);
  const { currencyIcon, fetchCurrency } = useFetchCurrency();
  const getBookings = async () => {
    try {
      const data = await getBookingList(currentPage, ITEMS_PER_PAGE);

      setBookings(data.bookings);
      setTotalItems(data.meta.total_items);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getBookings();
    fetchCurrency();
  }, [currentPage]);

  const handleNextBtn = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handlePrevBtn = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const TOTAL_PAGES = Math.ceil(totalItems / ITEMS_PER_PAGE);

  return (
    <Layout>
      <div className='mainContent'>
        <Card>
          <div className='topLine'>
            <h6>Bookings</h6>
          </div>
          <Table hover responsive className='bookings-table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount</th>
                <th>Booking Date</th>
                {/* <th>Start Time</th>
                <th>End Time</th> */}
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {bookings?.map((booking, i) => {
                const schedule = JSON.parse(booking.schedule) || {};
                return (
                  <tr key={i}>
                    <td>{booking.name}</td>
                    <td>{currencyIcon} {booking.amount}</td>
                    <td>
                      <Moment format='YYYY/MM/DD'>{schedule.book_date}</Moment>
                    </td>
                    {/* <td>{schedule.start_time}</td>
                    <td>{schedule.end_time}</td> */}
                    <td>
                      <Link to={`/invoice-details/${booking.invoice_id}`}>
                        {booking.invoice_id}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Pagination
            page={currentPage}
            paginationTitle='items'
            setPage={setCurrentPage}
            limit={ITEMS_PER_PAGE}
            setLimit={() => { }}
            prevButton={currentPage > 1 && currentPage <= TOTAL_PAGES}
            nextButton={currentPage < TOTAL_PAGES}
            pageValue={currentPage}
            totalValue={totalItems}
            prevPage={handlePrevBtn}
            nextPage={handleNextBtn}
            allRequestList={bookings}
          />
        </Card>
      </div>
    </Layout>
  );
};

export default Bookings;
