import React, { useState, useEffect } from 'react'
import AddMember from '../../Component/AddMember/AddMember';
import { Dropdown, Table } from 'react-bootstrap';
import { allMemberList, getMemberList, searchMember } from '../../api/member';
import "./Member.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../Component/Layout/Layout';
import { DESKIE_API as API } from '../../config';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import ViewMember from '../../Component/ViewMember/ViewMember';
import editPen from "../../Assets/Images/icon/edit-01.svg";
import EditMember from '../../Component/ViewMember/EditMember';
import { separateComma, truncateString } from '../../CommonFunction/Function';
import AssignSpaces from '../../Component/AssignSpaces/AssignSpaces';
import PhoneInput from 'react-phone-input-2';
import memberAvatar from "../../Assets/Images/icon/memberAvatar.svg";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import Pagination from './../../Component/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import dots from "../../Assets/Images/icon/dots-vertical_black.svg";
import Loader from '../../Component/Loader';
// import { monthlyDate } from '../../api/invoice';


const Member = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [memberShow, setMemberShow] = useState(false);
  const handleMemberClose = () => setMemberShow(false);
  const handleMemberShow = () => setMemberShow(true);
  const [memberId, setMemberId] = useState("");
  const [member, setMember] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [assignShow, setAssignShow] = useState(false);
  const handleAssignClose = () => setAssignShow(false);
  const [updateShow, setUpdateShow] = useState(false);
  const handleUpdateClose = () => setUpdateShow(false);
  const handleUpdateShow = () => setUpdateShow(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [limitValue, setLimitValue] = useState<number>(0);
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>();
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    allMemberList(limit, page, query, isDisabled).then((data) => {
      setMember(data.members);
      setTotalValue(data.total);
      setLimitValue(data.limit);
      setPageValue(data.page);
    }).catch((err) => { 
      console.log(err) 
    }).finally(() => {
      setLoading(false);
    });
  }, [show, updateShow, assignShow, limit, page, query, isDisabled]);

  // useEffect(() => {
  //   monthlyDate().then((data) => {}).catch((err) => {console.log(err)});
  // }, []);

  // member info view
  const memberView = (memberId: string) => {
    setMemberId(memberId);
    return navigate(`${memberId}`);
  }
  // member update view
  const memberUpdate = (memberId: string) => {
    setMemberId(memberId);
    setUpdateShow(true);
  }

  // search member
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const assignMembers = (spacesId: string) => {
    setAssignShow(true);
    setMemberId(spacesId);
    // setSpaceId(spacesId);
  }

  useEffect(() => {
    if (pageCount > 1) {
      setPrevButton(true)
    }
    if (page === 1) {
      setPrevButton(false)
    }
    // next button
    if (pageCount > 1) {
      setNextButton(true)
    }
    if (pageCount === page) {
      setNextButton(false)
    }
  }, [pageCount, page])


  const nextPage = () => {
    setPage(page + 1)
    setNextButton(true)
  }

  const prevPage = () => {
    setPage(page - 1)
  }

  const handleSort = (columnName: string) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(columnName);
      setSortOrder('asc');
    }
  };

  const sortedMembers = [...member].sort((a: any, b: any) => {
    if (!sortBy) return 0;
    if (sortBy === 'name') {
      return a.first_name.localeCompare(b.first_name) * (sortOrder === 'asc' ? 1 : -1);
    } else if (sortBy === 'space_images') {
      const aImage = a.space_images && a.space_images.length > 0;
      const bImage = b.space_images && b.space_images.length > 0;
      let comparison;
      if (aImage && !bImage) {
        comparison = 1;
      } else if (!aImage && bImage) {
        comparison = -1;
      } else if (aImage && bImage) {
        comparison = a.space_images.length - b.space_images.length;
      } else {
        comparison = 0;
      }
      return comparison * (sortOrder === 'asc' ? 1 : -1);
    }
    return 0;
  });


  return (
    <div id='member'>
      <Layout>
        <div className='mainContent'>
          <div className="memberBox">
            <div className="topLine">
              <div className='tab'>
                <span className={`tabItem ${!isDisabled && 'tabItemActive'}`}
                  onClick={() => {
                    setPage(1);
                    setIsDisabled(false)
                  }}
                >
                  Members
                </span>
                <span className={`tabItem ${isDisabled && 'tabItemActive'}`}
                  onClick={() => {
                    setIsDisabled(true);
                    setPage(1);
                  }}
                >
                  Disabled
                </span>
              </div>
              <div className='memberSearch'>
                <div className='searchInput'>
                  <input type="text" placeholder='Search members' value={query} onChange={handleInputChange} className='form-control' />
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <button onClick={handleShow}><FontAwesomeIcon icon={faPlus} /> Add Member</button>
              </div>
            </div>

            <div className="memberList">
              {loading? (
                  <Loader />
                ) : (
                  <>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th><label className="tableCheckBox">
                            <div className="contactCheck">
                              <input type="checkbox" name="agreement" />
                              <span className="checkmark"></span></div>
                          </label></th>
                          <th></th>
                          <th className='sortArrow' onClick={() => handleSort('name')}>Name {sortBy === "name" ? <>{sortOrder === "asc" ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</> : ""}</th>
                          <th>Email Address</th>
                          <th>Phone Number</th>
                          <th className='sortArrow' onClick={() => handleSort('space_images')}>Assignment {sortBy === "space_images" ? <>{sortOrder === "asc" ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}</> : ""}</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedMembers.map((data: any, index) => <tr key={`refer` + index}>
                          <td><label className="tableCheckBox">
                            <div className="contactCheck">
                              <input type="checkbox" name="agreement" />
                              <span className="checkmark"></span></div>
                          </label></td>
                          <td style={{ cursor: 'pointer' }} onClick={() => memberView(data.id)}>
                            {data.member_image ?
                              <><img src={`${API}/${data.member_image}`} alt="avatar" style={{ borderRadius: "50%", objectFit: "cover" }} /></>
                              : <><img className="default" src={memberAvatar} alt="avatar" style={{ borderRadius: "50%" }} /> </>
                            }
                          </td>
                          <td className='tableLink'>
                            <Link className='tableImage' to={`${data.id}`}>
                              {data.first_name} {data.last_name}
                            </Link>
                          </td>
                          <td>{data.email}</td>
                          <td className='memberPhone'><PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={data.phone_number} /></td>
                          <td className='tableAction text-center'>
                            {data.space_images ? <>
                              <div className="memberSpacesList">
                                {data.space_images && separateComma(data.space_images).map((member: any, i: number) =>
                                  <div key={`spaceImage` + i}>
                                    {member === "imgNull" ? <img className="avatar-icon36" alt="" src={spaceAvatar} />
                                      : <img className="avatar-icon36" alt="" src={`${API}/${member}`} />
                                    }
                                  </div>
                                )}
                                <div className="plusMember" onClick={() => assignMembers(data.id)}>
                                  <FontAwesomeIcon icon={faPlus} />
                                </div>
                              </div>
                            </>
                              : <button className='btn assign' onClick={() => assignMembers(data.id)}>Assign</button>
                            }
                          </td>
                          <td className='tableAction'>
                            <button className='btn view' onClick={() => memberView(data.id)}><FontAwesomeIcon icon={faEye as any} /></button>
                            <button className='btn edit' onClick={() => memberUpdate(data.id)}><img src={editPen} alt="edit" /></button>
                          </td>
                        </tr>)}
                      </tbody>
                    </Table>
                    <Pagination page={page} paginationTitle="items" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={member} />
                  </>
              )}
            </div>
            <div className="mobileMember">
              <div className="mobileFilesHeader mb-3">
                <div className='searchInput'>
                  <input type="text" placeholder='Search member' value={query} onChange={handleInputChange} className='form-control' />
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <button className='uploadIcon' onClick={handleShow}><FontAwesomeIcon icon={faPlus} /></button>
              </div>
              {sortedMembers.map((data: any, index) =>
                <div className='bookingPerson' style={{ flexDirection: "column", alignItems: "start", gap: 16, }} >
                  <div style={{ display: "flex", alignItems: "center" }} >
                    <div className='tableImage justify-content-center' style={{ cursor: "pointer" }} >
                      {data.member_image ? (
                        <img src={`${API}/${data.member_image}`} alt='avatar' style={{ objectFit: "cover", borderRadius: "50%", }} />
                      ) : (
                        <img src={spaceAvatar} alt='avatar' style={{ borderRadius: "50%" }} />
                      )}
                    </div>
                    <div className='tableLink'>
                      <Link to={`${data.id}`}>
                        {data.first_name} {data.last_name}
                      </Link>
                      <p>{data.email}</p>
                    </div>
                    {/* <img src={dots} alt="dots" /> */}
                    <div className="filesDropdown">
                      <div className="trashPost">
                        <Dropdown className='p-0'>
                          <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-toggle p-0'>
                            <img className="line-chart-up-04-icon" alt="" src={dots} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='postDelete'>
                            <Dropdown.Item className='custom-dropdown-toggle'><FontAwesomeIcon icon={faEye as any} /> View</Dropdown.Item>
                            <Dropdown.Item className='custom-dropdown-toggle'><img src={editPen} alt="edit" /> Edit</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div className='resourceType rscT memberPhone'>
                      <PhoneInput country={'us'} inputProps={{ readOnly: true }} disableCountryCode={false} value={data.phone_number} />
                    </div>
                    <div className='editBtn serc'>
                      <button style={{ width: "100%", height: "100%", paddingTop: "4px", paddingBottom: "4px" }}>
                        Reserve
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="mobilePagination">
                <Pagination page={page} paginationTitle="items" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={member} />
              </div>
            </div>

            <AddMember show={show} setShow={setShow} handleClose={handleClose} />
            <EditMember memberId={memberId} updateShow={updateShow} setUpdateShow={setUpdateShow} handleUpdateClose={handleUpdateClose} />
            <AssignSpaces memberId={memberId} assignShow={assignShow} setAssignShow={setAssignShow} handleAssignClose={handleAssignClose} />
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Member
