import React, { useState, useEffect } from 'react'
import { getStripe, stripeCreate, stripeUpdate } from '../../api/settings';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown } from 'react-bootstrap';
import dotLine from "../../Assets/Images/post/dots-horizontal.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSortDown } from '@fortawesome/free-solid-svg-icons';


const Finance = () => {
    const [secretId, setSecretId] = useState("");
    const [publishId, setPublishId] = useState("");
    const [stripeId, setStripeId] = useState("");
    const [stripeValue, setStripeValue] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);


    const stripeAdd = () => {
        let stripeInfo = {
            "id": uuidv4(),
            "secret_id": secretId,
            "publish_id": publishId,
            "currency": selectedCurrency
        }
        stripeCreate(stripeInfo).then(() => {
            showNotifications(TOAST_TYPE.success, "Stripe Update Successfully !!");
        }).catch((err) => { console.log(err) });
    }


    useEffect(() => {
        getStripe().then((data) => {
            setStripeId(data.data.id);
            setSecretId(data.data.secret_key);
            setPublishId(data.data.publish_key);
            setSelectedCurrency(data.data.currency);
            setStripeValue(true)
        }).catch((err) => { console.log(err) });
    }, []);

    const updateStripe = () => {
        let stripeInfo = {
            "secret_id": secretId,
            "publish_id": publishId,
            "currency": selectedCurrency
        }
        if (selectedCurrency) {
            stripeUpdate(stripeId, stripeInfo).then(() => {
                showNotifications(TOAST_TYPE.success, "Stripe Update Successfully !!");
            }).catch((err) => { console.log(err) });
        } else {
            showNotifications(TOAST_TYPE.error, "Please Choose Currency !!");
        }

    }



    return (
        <div>
            <div className="companyProfile">
                <div className='profileHeading'>
                    <h6>Company Finance</h6>
                    <p>Update your company’s info here.</p>
                </div>
                <div className='profileSave'>
                    <button className='cancel'>Cancel</button>
                    {stripeValue ? <button className='save' onClick={updateStripe}>Save</button>
                        : <button className='save' onClick={stripeAdd}>Save</button>}
                </div>
            </div>
            <div className="companyName">
                <p>Choose your Currency</p>
                <div className="memberInput col-8">
                    <Dropdown onSelect={(e) => setSelectedCurrency(e)}>
                        <Dropdown.Toggle variant="" className="custom-toggle" style={{ textTransform: 'uppercase' }}>
                            {selectedCurrency ? selectedCurrency : "Choose Currency"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="usd">United States Dollar (USD)</Dropdown.Item>
                            <Dropdown.Item eventKey="eur">Euro (EUR)</Dropdown.Item>
                            <Dropdown.Item eventKey="gbp">British Pound Sterling (GBP)</Dropdown.Item>
                            <Dropdown.Item eventKey="chf">Swiss franc (CHF)</Dropdown.Item>
                            <Dropdown.Item eventKey="ars">Argentine Peso (ARS)</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                </div>
            </div>
            <div className="companyName">
                <p>Stripe - Secret Key</p>
                <div className='rightSideSetting col-8'>
                    <div className="memberInput">
                        <input type="password" value={secretId} onChange={(e) => setSecretId(e.target.value)} placeholder='Enter your Stripe Secret Key' className='form-control' required />
                    </div>
                </div>
            </div>
            <div className="companyName publishKey">
                <p>Stripe - Publishable Key</p>
                <div className='rightSideSetting col-8'>
                    <div className="memberInput">
                        <input type="password" value={publishId} onChange={(e) => setPublishId(e.target.value)} placeholder='Enter your Stripe Publishable Key' className='form-control' required />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Finance