import { get, post } from "./base-api";

// agreement create
export const agreementCreate = (body={}) => {
    return post(`/agreementCreate`,body);
};
// agreement info
export const getAgreement = () => {
    return get(`/getAgreement`);
};
// tour create
export const tourAgreement = (body={}) => {
    return post(`/tourAgreement`,body);
};

// tour Agreement info
export const getTourAgreement = () => {
    return get(`/getTourAgreement`);
};