import React, { useEffect, useRef, useState } from 'react';
import { DESKIE_API as API } from '../../config';
import trash from "../../Assets/Images/icon/trash-02 (1).svg";
import uploadFile from "../../Assets/Images/icon/uploadIcon.svg";
import { getGlobalEditor, globalEditor } from '../../api/editor';
import { v4 as uuidv4 } from 'uuid';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';

const Editor = () => {
    const wrapperRef = useRef<HTMLInputElement>(null);
    const [count, setCount] = useState(0);
    const [headerLogo, setHeaderLogo] = useState<string | File | null>(null);
    const [heroLogo, setHeroLogo] = useState<string | File | null>(null);
    const [aboutLogo, setAboutLogo] = useState<string | File | null>(null);
    const [galleryOne, setGalleryOne] = useState<string | File | null>(null);
    const [galleryTwo, setGalleryTwo] = useState<string | File | null>(null);
    const [galleryThree, setGalleryThree] = useState<string | File | null>(null);
    const [testimonialOne, setTestimonialOne] = useState<string | File | null>(null);
    const [testimonialTwo, setTestimonialTwo] = useState<string | File | null>(null);
    const [testimonialThree, setTestimonialThree] = useState<string | File | null>(null);
    const [faqLogo, setFaqLogo] = useState<string | File | null>(null);
    const [footerLogo, setFooterLogo] = useState<string | File | null>(null);
    const [loginBtn, setLoginBtn] = useState(false);
    const [bookTourBtn, setBookTourBtn] = useState(false);
    const [rentableBtn, setRentableBtn] = useState(false);
    const [bookableBtn, setBookableBtn] = useState(false);
    const [bookATour, setBookATour] = useState(false);
    const [availableSpace, setAvailableSpace] = useState(false);
    const [amenities, setAmenities] = useState(false);
    const [galleryShow, setGalleryShow] = useState(false);
    const [testimonialShow, setTestimonialShow] = useState(false);
    const [resourceShow, setResourceShow] = useState(false);
    const [faqShow, setFaqShow] = useState(false);
    const [instagramShow, setInstagramShow] = useState(false);
    const [contactShow, setContactShow] = useState(false);

    const [settingId, setSettingId] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [heroHeader, setHeroHeader] = useState("");
    const [heroSubHeader, setHeroSubHeader] = useState("");
    const [aboutHeader, setAboutHeader] = useState("");
    const [aboutContent, setAboutContent] = useState("");
    const [tesHeaderOne, setTesHeaderOne] = useState("");
    const [tesContentOne, setTesContentOne] = useState("");
    const [tesHeaderTwo, setTesHeaderTwo] = useState("");
    const [tesContentTwo, setTesContentTwo] = useState("");
    const [tesHeaderThree, setTesHeaderThree] = useState("");
    const [tesContentThree, setTesContentThree] = useState("");
    const [faqQueOne, setFaqQueOne] = useState("");
    const [faqAnsOne, setFaqAnsOne] = useState("");
    const [faqQueTwo, setFaqQueTwo] = useState("");
    const [faqAnsTwo, setFaqAnsTwo] = useState("");
    const [faqQueThree, setFaqQueThree] = useState("");
    const [faqAnsThree, setFaqAnsThree] = useState("");
    const [faqQueFour, setFaqQueFour] = useState("");
    const [faqAnsFour, setFaqAnsFour] = useState("");
    const [faqQueFive, setFaqQueFive] = useState("");
    const [faqAnsFive, setFaqAnsFive] = useState("");
    const [instagramName, setInstagramName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [googleMap, setGoogleMap] = useState("");
    const [footerContent, setFooterContent] = useState("");

    const showLoginBtn = () => {
        setLoginBtn(!loginBtn)
    }
    const showBookTour = () => {
        setBookTourBtn(!bookTourBtn)
    }
    const showRental = () => {
        setRentableBtn(!rentableBtn)
    }
    const showBookable = () => {
        setBookableBtn(!bookableBtn)
    }
    const showBookATour = () => {
        setBookATour(!bookATour)
    }
    const showAssets = () => {
        setAvailableSpace(!availableSpace)
    }
    const showAmenities = () => {
        setAmenities(!amenities)
    }
    const showGallery = () => {
        setGalleryShow(!galleryShow)
    }
    const showTestimonial = () => {
        setTestimonialShow(!testimonialShow)
    }
    const showResource = () => {
        setResourceShow(!resourceShow)
    }
    const showFaq = () => {
        setFaqShow(!faqShow)
    }
    const showInstagram = () => {
        setInstagramShow(!instagramShow)
    }
    const showContact = () => {
        setContactShow(!contactShow)
    }


    function onFileLogoHeader(event: any, imageTag: string) {
        if (imageTag === "header") {
            setHeaderLogo(event.target.files[0]);
        }
        if (imageTag === "hero") {
            setHeroLogo(event.target.files[0]);
        }
        if (imageTag === "about") {
            setAboutLogo(event.target.files[0]);
        }
        if (imageTag === "galleryOne") {
            setGalleryOne(event.target.files[0]);
        }
        if (imageTag === "galleryTwo") {
            setGalleryTwo(event.target.files[0]);
        }
        if (imageTag === "galleryThree") {
            setGalleryThree(event.target.files[0]);
        }
        if (imageTag === "testimonialOne") {
            setTestimonialOne(event.target.files[0]);
        }
        if (imageTag === "testimonialTwo") {
            setTestimonialTwo(event.target.files[0]);
        }
        if (imageTag === "testimonialThree") {
            setTestimonialThree(event.target.files[0]);
        }
        if (imageTag === "faq") {
            setFaqLogo(event.target.files[0]);
        }
        if (imageTag === "footerLogo") {
            setFooterLogo(event.target.files[0]);
        }
    }

    useEffect(() => {
        getGlobalEditor().then((data) => {
            setSettingId(data.data.id);
            setEmail(data.data.email);
            setPhone(data.data.phone);
            setHeaderLogo(data.data.header_logo);
            setLoginBtn(data.data.login_button);
            setBookTourBtn(data.data.assets_book_a_tour);
            setHeroLogo(data.data.hero_logo);
            setHeroHeader(data.data.hero_header);
            setHeroHeader(data.data.hero_header);
            setHeroSubHeader(data.data.hero_sub_header);
            setRentableBtn(data.data.rentable_button);
            setBookableBtn(data.data.bookable_button);
            setAboutLogo(data.data.about_logo);
            setAboutHeader(data.data.about_header);
            setAboutContent(data.data.about_content);
            setBookATour(data.data.about_book_a_tour);
            setAvailableSpace(data.data.assets_book_a_tour);
            setAmenities(data.data.amenities_section);
            setGalleryShow(data.data.gallery_section);
            setGalleryOne(data.data.gallery_one);
            setGalleryTwo(data.data.gallery_two);
            setGalleryThree(data.data.gallery_three);
            setTestimonialShow(data.data.testimonial_section);
            setTestimonialOne(data.data.testimonial_one);
            setTestimonialTwo(data.data.testimonial_two);
            setTestimonialThree(data.data.testimonial_three);
            setTesHeaderOne(data.data.tes_header_one);
            setTesContentOne(data.data.tes_content_one);
            setTesHeaderTwo(data.data.tes_header_two);
            setTesContentTwo(data.data.tes_content_two);
            setTesHeaderThree(data.data.tes_header_three);
            setTesContentThree(data.data.tes_content_three);
            setResourceShow(data.data.resource_section);
            setFaqShow(data.data.faq_section);
            setFaqLogo(data.data.faq_logo);
            setFaqQueOne(data.data.faq_que_one);
            setFaqAnsOne(data.data.faq_ans_one);
            setFaqQueTwo(data.data.faq_que_two);
            setFaqAnsTwo(data.data.faq_ans_two);
            setFaqQueThree(data.data.faq_que_three);
            setFaqAnsThree(data.data.faq_ans_three);
            setFaqQueFour(data.data.faq_que_four);
            setFaqAnsFour(data.data.faq_ans_four);
            setFaqQueFive(data.data.faq_que_five);
            setFaqAnsFive(data.data.faq_ans_five);
            setInstagramShow(data.data.instagram_section);
            setInstagramName(data.data.instagram_name);
            setContactShow(data.data.contact_section);
            setContactEmail(data.data.contact_email);
            setGoogleMap(data.data.google_map);
            setFooterLogo(data.data.footer_logo);
            setFooterContent(data.data.footer_content);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const globalUpdate = () => {
        let editorJson = {
            "settingId": settingId ? settingId : uuidv4(),
            "email": email,
            "phone": phone,
            "hero_header": heroHeader,
            "hero_sub_header": heroSubHeader,
            "about_header": aboutHeader,
            "about_content": aboutContent,
            "tes_header_one": tesHeaderOne,
            "tes_content_one": tesContentOne,
            "tes_header_two": tesHeaderTwo,
            "tes_content_two": tesContentTwo,
            "tes_header_three": tesHeaderThree,
            "tes_content_three": tesContentThree,
            "faq_que_one": faqQueOne,
            "faq_ans_one": faqAnsOne,
            "faq_que_two": faqQueTwo,
            "faq_ans_two": faqAnsTwo,
            "faq_que_three": faqQueThree,
            "faq_ans_three": faqAnsThree,
            "faq_que_four": faqQueFour,
            "faq_ans_four": faqAnsFour,
            "faq_que_five": faqQueFive,
            "faq_ans_five": faqAnsFive,
            "instagram_name": instagramName,
            "contact_email": contactEmail,
            "google_map": googleMap,
            "footer_content": footerContent,
            "header_logo": headerLogo,
            "hero_logo": heroLogo,
            "about_logo": aboutLogo,
            "gallery_one": galleryOne,
            "gallery_two": galleryTwo,
            "gallery_three": galleryThree,
            "testimonial_one": testimonialOne,
            "testimonial_two": testimonialTwo,
            "testimonial_three": testimonialThree,
            "faq_logo": faqLogo,
            "footer_logo": footerLogo,
            "assets_book_a_tour": availableSpace,
            "about_book_a_tour": bookATour,
            "header_book_a_tour": bookTourBtn,
            "login_button": loginBtn,
            "rentable_button": rentableBtn,
            "bookable_button": bookableBtn,
            "amenities_section": amenities,
            "gallery_section": galleryShow,
            "testimonial_section": testimonialShow,
            "resource_section": resourceShow,
            "faq_section": faqShow,
            "instagram_section": instagramShow,
            "contact_section": contactShow
        };

        globalEditor(editorJson).then((data) => {
            showNotifications(TOAST_TYPE.success, data.message);
            setCount(count + 1)
        }).catch((err) => { console.log(err); });
    }



    return (
        <>
            <div className='profileHeading' style={{ borderBottom: '1px solid #EAECF0' }}>
                <h6>Website Customization (Page Editor)</h6>
                <p>Enable, disable and customize the content of your landing page.</p>
            </div>
            {/* Top navbar */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Top Bar</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p>Email Address</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder='Email Address' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Phone Number</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" placeholder='Phone Number' />
                        </div>
                    </div>
                </div>
            </div>
            {/* Navigation Bar */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Navigation Bar</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p>Header Logo</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon widthLogoBox">
                            {headerLogo ? (
                                typeof headerLogo === "string" ?
                                    <img src={`${API}/${headerLogo}`} className="widthLogo" alt="change-logo" />
                                    : <img src={URL.createObjectURL(headerLogo)} className="widthLogo" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "header")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show Login Button?</p>
                    <div className="authToggle mt-0">
                        {loginBtn === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showLoginBtn} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showLoginBtn} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Book a Tour” Button?</p>
                    <div className="authToggle mt-0">
                        {bookTourBtn === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showBookTour} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showBookTour} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
            </div>
            {/* Hero Banner */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Hero</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p>Hero Background Image</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {heroLogo ? (
                                typeof heroLogo === "string" ?
                                    <img src={`${API}/${heroLogo}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(heroLogo)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "hero")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Header</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={heroHeader} onChange={(e) => setHeroHeader(e.target.value)} className="form-control" placeholder='Header' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Subheader</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={heroSubHeader} onChange={(e) => setHeroSubHeader(e.target.value)} className="form-control" placeholder='Subheader' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show Rentable Spaces (“Check Availability”) Button?</p>
                    <div className="authToggle mt-0">
                        {rentableBtn === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showRental} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showRental} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show Bookable Spaces Button?</p>
                    <div className="authToggle mt-0">
                        {bookableBtn === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showBookable} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showBookable} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
            </div>
            {/* About */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>About</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p>About Image</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {aboutLogo ? (
                                typeof aboutLogo === "string" ?
                                    <img src={`${API}/${aboutLogo}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(aboutLogo)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "about")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Header</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={aboutHeader} onChange={(e) => setAboutHeader(e.target.value)} className="form-control" placeholder='Header' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Content</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={aboutContent} onChange={(e) => setAboutContent(e.target.value)} placeholder='Enter your content' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Book a Tour” Button?</p>
                    <div className="authToggle mt-0">
                        {bookATour === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showBookATour} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showBookATour} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
            </div>
            {/* Available Spaces */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Assets (Available Spaces)</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Book a Tour” Button?</p>
                    <div className="authToggle mt-0">
                        {availableSpace === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showAssets} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showAssets} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
            </div>
            {/* Amenities */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Amenities</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Amenities” Section?</p>
                    <div className="authToggle mt-0">
                        {amenities === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showAmenities} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showAmenities} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
            </div>
            {/* Photo Gallery */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Photo Gallery</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Photo Gallery” Section?</p>
                    <div className="authToggle mt-0">
                        {galleryShow === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showGallery} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showGallery} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
                <div className="companyName">
                    <p>Photo 1 (Large)</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {galleryOne ? (
                                typeof galleryOne === "string" ?
                                    <img src={`${API}/${galleryOne}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(galleryOne)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "galleryOne")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Photo 2 (Large)</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {galleryTwo ? (
                                typeof galleryTwo === "string" ?
                                    <img src={`${API}/${galleryTwo}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(galleryTwo)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "galleryTwo")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Photo 3 (Large)</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {galleryThree ? (
                                typeof galleryThree === "string" ?
                                    <img src={`${API}/${galleryThree}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(galleryThree)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "galleryThree")} />
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonials */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Testimonials</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Testimonials” Section?</p>
                    <div className="authToggle mt-0">
                        {testimonialShow === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showTestimonial} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showTestimonial} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 1 - Photo</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {testimonialOne ? (
                                typeof testimonialOne === "string" ?
                                    <img src={`${API}/${testimonialOne}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(testimonialOne)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "testimonialOne")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 1 - Name</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={tesHeaderOne} onChange={(e) => setTesHeaderOne(e.target.value)} className="form-control" placeholder='Testimonial 1 - Name' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 1 - Content</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={tesContentOne} onChange={(e) => setTesContentOne(e.target.value)} placeholder='Enter your content' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 2 - Photo</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {testimonialTwo ? (
                                typeof testimonialTwo === "string" ?
                                    <img src={`${API}/${testimonialTwo}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(testimonialTwo)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "testimonialTwo")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 2 - Name</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={tesHeaderTwo} onChange={(e) => setTesHeaderTwo(e.target.value)} className="form-control" placeholder='Testimonial 2 - Name' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 2 - Content</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={tesContentTwo} onChange={(e) => setTesContentTwo(e.target.value)} placeholder='Enter your content' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 2 - Photo</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {testimonialThree ? (
                                typeof testimonialThree === "string" ?
                                    <img src={`${API}/${testimonialThree}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(testimonialThree)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "testimonialThree")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 3 - Name</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={tesHeaderThree} onChange={(e) => setTesHeaderThree(e.target.value)} className="form-control" placeholder='Testimonial 3 - Name' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Testimonial 3 - Content</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={tesContentThree} onChange={(e) => setTesContentThree(e.target.value)} placeholder='Enter your content' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
            </div>
            {/* Resources */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Resources</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Resources” Section?</p>
                    <div className="authToggle mt-0">
                        {resourceShow === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showResource} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showResource} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
            </div>
            {/* Faq */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Faq</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “FAQ” Section?</p>
                    <div className="authToggle mt-0">
                        {faqShow === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showFaq} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showFaq} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
                <div className="companyName">
                    <p>FAQ Photo</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon">
                            {faqLogo ? (
                                typeof faqLogo === "string" ?
                                    <img src={`${API}/${faqLogo}`} className="largeImage" alt="change-logo" />
                                    : <img src={URL.createObjectURL(faqLogo)} className="largeImage" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "faq")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Question 1</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={faqQueOne} onChange={(e) => setFaqQueOne(e.target.value)} className="form-control" placeholder='Question 1' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Answer 1</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={faqAnsOne} onChange={(e) => setFaqAnsOne(e.target.value)} placeholder='Enter your answer' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Question 2</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={faqQueTwo} onChange={(e) => setFaqQueTwo(e.target.value)} className="form-control" placeholder='Question 2' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Answer 2</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={faqAnsTwo} onChange={(e) => setFaqAnsTwo(e.target.value)} placeholder='Enter your answer' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Question 3</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={faqQueThree} onChange={(e) => setFaqQueThree(e.target.value)} className="form-control" placeholder='Question 3' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Answer 3</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={faqAnsThree} onChange={(e) => setFaqAnsThree(e.target.value)} placeholder='Enter your answer' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Question 4</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={faqQueFour} onChange={(e) => setFaqQueFour(e.target.value)} className="form-control" placeholder='Question 4' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Answer 4</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={faqAnsFour} onChange={(e) => setFaqAnsFour(e.target.value)} placeholder='Enter your answer' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Question 5</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={faqQueFive} onChange={(e) => setFaqQueFive(e.target.value)} className="form-control" placeholder='Question 5' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Answer 5</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={faqAnsFive} onChange={(e) => setFaqAnsFive(e.target.value)} placeholder='Enter your content' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
            </div>
            {/* Instagram */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Instagram</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Instagram” Section?</p>
                    <div className="authToggle mt-0">
                        {instagramShow === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showInstagram} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showInstagram} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
                <div className="companyName">
                    <p>Instagram Username</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="text" value={instagramName} onChange={(e) => setInstagramName(e.target.value)} className="form-control" placeholder='Instagram Username' />
                        </div>
                    </div>
                </div>
            </div>
            {/* Contact */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Contact</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p className='mb-0'>Show “Contact” Section?</p>
                    <div className="authToggle mt-0">
                        {contactShow === true ?
                            <label className="switch">
                                <input type="checkbox" onClick={showContact} defaultChecked />
                                <span className="slider round"></span>
                            </label> :
                            <label className="switch">
                                <input type="checkbox" onClick={showContact} />
                                <span className="slider round"></span>
                            </label>}
                    </div>
                </div>
                <div className="companyName">
                    <p>Contact Form Email Address</p>
                    <div className="rightSideSetting col-8">
                        <div className="memberInput d-flex">
                            <input type="email" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} className="form-control" placeholder='Contact Form Email Address' />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Address (Map)</p>
                    <div className="rightSideSetting col-8">
                        <label htmlFor="">**Share Embed a IFrame map**</label>
                        <div className="memberInput d-flex">
                            <input type="text" value={googleMap} onChange={(e) => setGoogleMap(e.target.value)} className="form-control" placeholder='<iframe src="https://www.google.com/maps/embed" loading="lazy"></iframe>' />
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer */}
            <div className="websiteBox">
                <div className="websiteHeading">
                    <h6>Footer</h6>
                    <div className='profileSave'>
                        <button className='cancel'>Cancel</button>
                        <button className='save' onClick={globalUpdate}>Save</button>
                    </div>
                </div>
                <div className="companyName">
                    <p>Footer Logo</p>
                    <div className='rightFileSetting col-8'>
                        <div className="logoImageBox liteIcon widthLogoBox">
                            {footerLogo ? (
                                typeof footerLogo === "string" ?
                                    <img src={`${API}/${footerLogo}`} className="widthLogo" alt="change-logo" />
                                    : <img src={URL.createObjectURL(footerLogo)} className="widthLogo" alt="change-logo" />
                            ) : <p>No image</p>}
                            <img src={trash} className='trashBtn' alt="delete" />
                        </div>
                        <div ref={wrapperRef} className="drop-file-input">
                            <div className="drop-file-input__label">
                                <img src={uploadFile} alt="" />
                            </div>
                            <input type="file" onChange={(event) => onFileLogoHeader(event, "footerLogo")} />
                        </div>
                    </div>
                </div>
                <div className="companyName">
                    <p>Content</p>
                    <div className="rightSideSetting col-8">
                        <div className="companyInput d-flex">
                            <textarea value={footerContent} onChange={(e) => setFooterContent(e.target.value)} placeholder='Enter your content' className='form-control' rows={3}></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Editor