import React, { useState, useEffect } from 'react'
import html2pdf from "html2pdf.js";
import moment from 'moment';
import { DESKIE_API as API } from '../../../config';
import { getAgreement } from '../../../api/agreement';
import { singleProfile } from '../../../api/settings';
import { singleJwtMember, singleMember } from '../../../api/member';

const Agreement = () => {
    const [memberDetails, setMemberDetails] = useState<any>({});
    const [content, setContent] = useState('')
    const [signatureImageUrl, setSignatureImageUrl] = useState('')
    const [logoImageUrl, setLogoImageUrl] = useState('')
    const [agreementId, setAgreementId] = useState('')
    const [companyName, setCompanyName] = useState("");
    const [darkLogoImage, setDarkLogoImage] = useState("");
    const [userId, setUserId] = useState('');
    const [address, setAddress] = useState("");
    const [agreementDate, setAgreementDate] = useState('')

    const getSingleProfile = async () => {
        try {
            const { data } = await singleProfile();
            setCompanyName(data.company_name);
            setDarkLogoImage(data.company_logo_dark);
            setAddress(data.address);
        } catch (err) {
            console.log(err);
        };
    };

    useEffect(() => {
        getAgreement().then((data) => {
            if (data.data) {
                setContent(data.data.agreement);
                setAgreementId(data.data.id);
                setAgreementDate(data.data.created_at);
            }
        }).catch((err) => { console.log(err) });
        getSingleProfile();
        singleJwtMember()
            .then((res) => {
                const { data } = res.data;
                setUserId(data.id);
                setMemberDetails(data && data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])
    // pdf download

    function toBase64(url: any, callback: any) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
            var uInt8Array: any = new Uint8Array(this.response);
            var i = uInt8Array.length;
            var binaryString = String.fromCharCode.apply(null, uInt8Array);
            var base64String = window.btoa(binaryString); // Base64 encode
            callback(base64String);
        };
        xhr.send();
    }
    toBase64(`${API}/${memberDetails.signature_image}`, (base64Image: any) => {
        const signatureImageUrl = `data:image/png;base64,${base64Image}`;
        setSignatureImageUrl(signatureImageUrl);
    });
    toBase64(`${API}/${encodeURI(darkLogoImage)}`, (base64Image: any) => {
        const signatureImageUrl = `data:image/png;base64,${base64Image}`;
        setLogoImageUrl(signatureImageUrl);
    });


    const agreementText = () => {
        const element = document.createElement("div");
        element.id = "agreement-content";
        element.style.paddingTop = "30px";
        element.style.paddingLeft = "30px";
        element.style.paddingRight = "30px";
        element.style.paddingBottom = "30px";
        let addressHtml = '';
        if (address) {
            addressHtml = `<p style="font-size: 10px !important; color: black;">Address: ${address}</p>`;
        }
        element.innerHTML = `
             <div style="display: block; margin: 0 auto; text-align: center;">
             <img src="${logoImageUrl}" alt="Signature" style="max-width: 100px; max-height: 60px;" /> ${companyName}
             </div>
        
             ${content}
            
            
            <div class="page-break"></div>
    
             <div class="" style="margin-top: 30px; text-align: left;">
                <h6 style="font-size: 23px !important; color: #222; font-weight: 800;">User Information:</h6>
                <p style="font-size: 14px !important; color: black; font-weight: 700;">Name: ${memberDetails.first_name} ${memberDetails.last_name}</p>
                <div class="memberInfo" style="font-size: 11px !important; border: 1px solid rgba(234, 236, 240, 1);">
                                <div class="memberInfoBox" style="padding: 12px !important">
                                    <h6 style="font-size: 12px !important; color: black;">Phone Number</h6>
                                    <p style="font-size: 12px !important; color: black;">${memberDetails.phone_number}</p>
                                </div>
                                <div class="memberInfoBox" style="padding: 12px !important; border-left: 1px solid  rgba(234, 236, 240, 1); border-right: 1px solid  rgba(234, 236, 240, 1)">
                                    <h6 style="font-size: 12px !important; color: black;">Email Address</h6>
                                    <p style="font-size: 12px !important; color: black;">${memberDetails.email}</p>
                                </div>
                                <div class="memberInfoBox" style="padding: 12px !important">
                                    <h6 style="font-size: 12px !important; color: black;">Account Signup Date</h6>
                                    <p style="font-size: 12px !important; color: black;">${moment(memberDetails.created_at).format("MMMM DD, YYYY")}</p>
                                </div>
                                <div class="memberInfoBox" style="padding: 12px !important">
                                    <h6 style="font-size: 12px !important; color: black;">Business Name</h6>
                                    <p style="font-size: 12px !important; color: black;">${memberDetails.business_name}</p>
                                </div>
                                <div class="memberInfoBox" style="padding: 12px !important; border-left: 1px solid  rgba(234, 236, 240, 1); border-right: 1px solid  rgba(234, 236, 240, 1)">
                                      <h6 style="font-size: 12px !important; color: black;">Business Email</h6>
                                    <p style="font-size: 12px !important; color: black;">${memberDetails.business_email}</p>
                                </div>
                                <div class="memberInfoBox" style="padding: 12px !important">
                                 <h6 style="font-size: 12px !important; color: black;">Business Phone</h6>
                                    <p style="font-size: 12px !important; color: black;">${memberDetails.business_phone}</p>
                                </div>
                            </div>
    
              </div>
          
              <div style="margin-top: 30px; text-align: left;">
                <img src="${signatureImageUrl}" alt="Signature" style="max-width: 200px; display: block;" />
                <p style="margin-top: 10px; font-weight: bold;">${memberDetails.signature}</p>
                <p style="font-size: 10px !important; color: black;">Date Signed: ${moment(memberDetails.created_at).format("MMMM DD, YYYY")}</p>
                 <p style="font-size: 10px !important; color: black;">Agreement ID: ${agreementId}</p>
                 <p style="font-size: 10px !important; color: black;">Signature ID: ${agreementId}</p> 
                 ${addressHtml}
                 <p style="font-size: 10px !important; color: black;">Agreement Upload Date: ${moment(agreementDate).format("MMMM DD, YYYY")}</p>
              </div>
            `;

        return element;
    };




    const downloadPdf = () => {
        const agreementContent = agreementText();
        const options = {
            margin: 1,
            filename: `${memberDetails.first_name}-${memberDetails.last_name}-Membership-Agreement`,
            html2canvas: {
                scale: 2,
                logging: true
            },
            jsPDF: {
                unit: "mm",
                format: "a4",
                orientation: "portrait",
                font: "OpenSans",
                fontSize: 12,
            },
            pageBreak: { mode: 'avoid-all', before: '.page-break' }
        };

        html2pdf()
            .from(agreementContent)
            .set(options)
            .save()
    };

    return (
        <div>
            <div className='companyProfile'>
                <div className='profileHeading'>
                    <h6>Download Membership Agreement</h6>
                    <p>Signed {moment(memberDetails.created_at).format("MMMM DD, YYYY")} - Agreement ID: {agreementId}</p>
                </div>
                <div className='profileSave'>
                    <button className='save' onClick={downloadPdf}>Download</button>
                </div>
            </div>
        </div>
    )
}

export default Agreement