import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "react-bootstrap";
import { DESKIE_API as API } from "../../config";
import { publicResourceList } from "../../api/resource";
import { DESKIE_API } from "../../config";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import ResourceBooking from "../../Component/ResourceBooking/ResourceBooking";
import { singleProfile } from "../../api/settings";
import logo from "../../Assets/Images/logo/logo.svg";
import { Link } from "react-router-dom";
import MobileBilling from "../../Component/MobileBilling/MobileBilling";
import { useFetchCurrency } from "../../CommonFunction/Function";

type ResourceList = {
  capacity: string;
  created_at: string;
  id: string;
  image: string;
  member_rate: string;
  member_time: string;
  name: string;
  public_rate: string;
  public_time: string;
  type: string;
}[];

const ITEMS_PER_PAGE = 10;

function PublicResources() {
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState<ResourceList>([]);
  const [selectedResource, setSelectedResource] = useState<ResourceList[0]>();
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState<any>();
  const { currencyIcon, fetchCurrency } = useFetchCurrency();
  const getSingleProfile = async () => {
    try {
      const { data } = await singleProfile();
      setProfile(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSingleProfile();
    fetchCurrency();
    const getResources = async () => {
      try {
        const res = await publicResourceList(ITEMS_PER_PAGE, page, "");
        setResources(res.data);
        setTotalItems(res.meta.totalRows);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getResources();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>Loading....</p>
      </div>
    );
  }


  const filteredResource = resources.filter((resource: any) => resource.public_rate !== null && resource.public_rate !== "");


  return (
    <div className=''>
      <section
        className='signUpSection'
        style={{
          backgroundImage: `linear-gradient(rgba(31, 41, 55, 0.9), rgba(31, 41, 55, 0.94)), url(${profile && `${API}/${encodeURI(profile.background)}`
            })`,
        }}
      >
        <div className='signUpBox'>
          <div className='logo'>
            {profile && profile.company_logo_dark ? (
              <img src={`${API}/${profile.company_logo_dark}`} alt='logo' />
            ) : (
              <img src={logo} alt='logo' />
            )}
          </div>
          <div className='publicAssets'>
            <div className='publicMember'>
              <p>Book a Resource</p>
              <Link to="/public/assets">Explore Long-Term Options</Link>
            </div>
            <Card
              style={{
                display: isOpen ? "none" : "flex",
                border: "none",
              }}
            >
              <CardBody
                style={{
                  overflowY: "auto",
                }}
              >
                {filteredResource?.map((resource) => {
                  return (
                    <div className='publicAsset'>
                      <div className='assetImage'>
                        {resource.image ? (
                          <img
                            src={`${API}/${resource.image}`}
                            alt='avatar'
                            style={{ objectFit: "cover" }}
                          />
                        ) : (
                          <img src={spaceAvatar} alt='avatar' />
                        )}
                        <div className='mobileAssets'>
                          <p>{resource.name} <div className='resourceType'>
                            {resource.type === "meeting" ? (
                              <span className='meeting'>Meeting Space</span>
                            ) : (
                              ""
                            )}
                            {resource.type === "equipment" ? (
                              <span className='equipment'>Equipment</span>
                            ) : (
                              ""
                            )}
                            {resource.type === "workspace" ? (
                              <span className='workspace'>Workspace</span>
                            ) : (
                              ""
                            )}
                            {resource.type === "other" ? (
                              <span className='other'>Other</span>
                            ) : (
                              ""
                            )}
                          </div></p>
                          <span>
                            {currencyIcon} {resource.public_rate} / {resource.public_time}
                          </span>
                        </div>
                      </div>
                      <div className='assetBtnInfo mobileResourceBtn'>
                        <div className='resourceType'>
                          {resource.type === "meeting" ? (
                            <span className='meeting'>Meeting Space</span>
                          ) : (
                            ""
                          )}
                          {resource.type === "equipment" ? (
                            <span className='equipment'>Equipment</span>
                          ) : (
                            ""
                          )}
                          {resource.type === "workspace" ? (
                            <span className='workspace'>Workspace</span>
                          ) : (
                            ""
                          )}
                          {resource.type === "other" ? (
                            <span className='other'>Other</span>
                          ) : (
                            ""
                          )}
                        </div>
                        <button className="mobileAssetsBtn" onClick={() => { setSelectedResource(resource); setIsOpen(true); }}>Book Now</button>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
            {/* <ResourceBooking
              key={selectedResource?.id}
              resourceDetails={selectedResource}
              paymentShow={isOpen}
              setPaymentShow={setIsOpen}
              handlePaymentClose={() => setIsOpen(false)}
              isPublic={true}
            /> */}
            {isOpen ? <MobileBilling key={selectedResource?.id} resourceDetails={selectedResource} paymentShow={isOpen} setPaymentShow={setIsOpen} handlePaymentClose={() => setIsOpen(false)} isPublic={true} /> : ""}

          </div>
        </div>
      </section>
    </div>
  );
}

export default PublicResources;
