import { useEffect, useState } from "react";
import { fetchResourceCalendarFeed } from "../../api/resource";


const ResourceCalendar = () => {
  const [calendarFeed, setCalendarFeed] = useState("");

  const getCalendarFeed = async () => {
    try {
      const res = await fetchResourceCalendarFeed();
      setCalendarFeed(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCalendarFeed();
  }, []);

  return (
    <>{calendarFeed}</>
  )
};

export default ResourceCalendar;