import React, { useState, useEffect, forwardRef } from "react";
import { Col, Container, Dropdown, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faXmark,
  faChevronDown,
  faSearch,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import assignmentIcon from "../../Assets/Images/icon/assignmentIcon1.svg";
import "./AssignMember.css";
import { memberAddSpaces, singleSpaces } from "../../api/spaces";
import { DESKIE_API as API } from "../../config";
import { getMemberList, allMemberList } from "../../api/member";
import { showNotifications, TOAST_TYPE } from "../../CommonFunction/toaster";
import DatePicker from "react-datepicker";
import calenderIcon from "../../Assets/Images/icon/calendar.svg";
import memberIcon from "../../Assets/Images/icon/memberAvatar.svg";
import spaceIcon from "../../Assets/Images/icon/spaceAvatar.png";
import { v4 as uuidv4 } from "uuid";
import { addAssignment } from "../../api/assignments";
import { useFetchCurrency } from "../../CommonFunction/Function";

interface AssignMemberProps {
  handleAssignClose: () => void;
  assignShow: boolean;
  setAssignShow: (type: boolean) => void;
  spaceId: string;
}

const AssignMember = ({
  spaceId,
  assignShow,
  setAssignShow,
  handleAssignClose,
}: AssignMemberProps) => {
  const [authValue, setAuthValue] = useState(false);
  const [name, setName] = useState("");
  const [rate, setRate] = useState("");
  const [selectName, setSelectName] = useState("");
  const [selectEmail, setSelectEmail] = useState("");
  const [memberImage, setMemberImage] = useState("");
  const [spaceImage, setSpaceImage] = useState("");
  const [member, setMember] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [searchMembers, setSearchMembers] = useState("");
  const [memberId, setMemberId] = useState("");
  const [amount, setAmount] = useState("");
  const { currencyIcon, fetchCurrency } = useFetchCurrency();
  const initialRenewalDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    return date;
  };
  const [renewalDate, setRenewalDate] = useState<any>(initialRenewalDate());
  const [frequency, setFrequency] = useState("monthly");
  const [discountAmount, setDiscountAmount] = useState("");
  const [memberError, setMemberError] = useState(false);

  useEffect(() => {
    fetchCurrency();
    allMemberList().then((data) => {
      setMember(data.members);
    })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const filteredMembers = member?.filter(
    (member: any) =>
      member.first_name.toLowerCase().includes(searchMembers.toLowerCase()) ||
      member.last_name.toLowerCase().includes(searchMembers.toLowerCase()) ||
      member.email.toLowerCase().includes(searchMembers.toLowerCase())
  );

  useEffect(() => {
    if (!spaceId) return;

    singleSpaces(spaceId)
      .then((data) => {
        setName(data.data && data.data.name);
        setRate(data.data && data.data.rate);
        setAmount(data.data && data.data.rate);
        setSpaceImage(data.data && data.data.space_image);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [spaceId]);

  const authClick = () => {
    setAuthValue(!authValue);
    if (authValue === false) {
      const today = new Date();
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      const daysRemaining = lastDayOfMonth.getDate() - today.getDate() + 1;
      const daysInMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getDate();
      const rentForCurrentMonth = (
        (parseInt(amount) / daysInMonth) *
        daysRemaining
      ).toFixed(2);
      setDiscountAmount(rentForCurrentMonth.toString());
    } else {
      setDiscountAmount("");
    }
  };

  const selectMember = (member: any) => {
    setSelectName(`${member.first_name} ${member.last_name}`);
    setSelectEmail(member.email);
    setMemberId(member.id);
    setMemberImage(member.member_image);
    setMemberError(false);
  };

  const handleMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMembers(e.target.value);
  };

  const spaceBooking = () => {
    if (!memberId) {
      setMemberError(true);
      return;
    }
    // const company = localStorage.getItem("company");
    // const userInfo = company ? JSON.parse(company) : null;
    // let invId= uuidv4();
    // let invoiceInfo = {
    //   "id": invId,
    //   "spaces_id": spaceId,
    //   "member_id": memberId,
    //   "amount": discountAmount ? discountAmount : amount,
    //   "renewal_date": renewalDate,
    //   "renewal_frequency": authValue ? "today" : frequency,
    //   "user_email": userInfo.user.email,
    //   "invoice_type": "invoice"
    // }
    let assignInfo = {
      member_id: memberId,
      amount: discountAmount ? discountAmount : amount,
      booking_type: authValue ? "immidiate" : "end_of_month",
    };

    // memberAddSpaces(invoiceInfo).then((data) => {
    //   if (data.statusCode !== 200) {
    //     showNotifications(TOAST_TYPE.error, data.message);
    //   }
    //   else {
    //     setAssignShow(false);
    //     setSelectName("");
    //     setSelectEmail("");
    //     setMemberId("");
    //     setMemberImage("");
    //     showNotifications(TOAST_TYPE.success, data.message);
    //   }

    // }).catch((err) => {
    //   console.log(err);
    // }).finally(() => {
    //   setAssignShow(false);
    // })

    addAssignment(spaceId, assignInfo)
      .then((data) => {
        setMemberId("");
        setSelectName("");
        setSelectEmail("");
        showNotifications(TOAST_TYPE.success, data.message);
      })
      .catch(() => { })
      .finally(() => {
        setAssignShow(false);
      });
  };

  return (
    <>
      <Modal
        show={assignShow}
        onHide={handleAssignClose}
        centered
        size='lg'
        className='assignModal'
        id='assignMember'
      >
        <div className='addMemberForm'>
          <button className='closeModal' onClick={handleAssignClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <Container className='px-0'>
            <Row>
              <Col md={12}>
                <div className='addMemberHeading'>
                  <img src={assignmentIcon} alt='member' />
                  <p>Assign</p>
                </div>
              </Col>
              <Col md={12}>
                <div className='memberSpaces'>
                  <div className='memberInfos assignBox'>
                    <div className='dropdown'>
                      <div
                        onClick={(e) => {
                          setIsActive(!isActive);
                        }}
                        className='dropdown-btn'
                      >
                        <div className='d-flex tableImage'>
                          {selectEmail.length ? (
                            <>
                              {memberImage ? (
                                <img
                                  src={`${API}/${memberImage}`}
                                  alt='avatar'
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <img
                                  className='default'
                                  src={memberIcon}
                                  alt='avatar'
                                />
                              )}
                              <div>
                                <p>
                                  {selectName.length > 20
                                    ? selectName.substring(0, 20) + "..."
                                    : selectName}
                                </p>
                                <span>
                                  {selectEmail.length > 20
                                    ? selectEmail.substring(0, 20) + "..."
                                    : selectEmail}
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <p>Select Member</p>
                              </div>
                            </>
                          )}
                        </div>
                        <span>
                          <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                      </div>
                      <div
                        className='dropdown-content'
                        style={{ display: isActive ? "block" : "none" }}
                      >
                        <div className='assignInput px-2'>
                          <FontAwesomeIcon icon={faSearch} />
                          <input
                            type='text'
                            placeholder='Search member'
                            onChange={handleMemberChange}
                            className='form-control'
                          />
                        </div>
                        <div className='member-container'>
                          {filteredMembers.map((data: any, index) => (
                            <div
                              key={`assignMember` + index}
                              onClick={(e) => {
                                setIsActive(!isActive);
                                selectMember(data);
                              }}
                              className='item tableImage my-2'
                            >
                              {data.member_image ? (
                                <img
                                  src={`${API}/${data.member_image}`}
                                  alt='avatar'
                                  style={{ objectFit: "cover" }}
                                />
                              ) : (
                                <img
                                  className='default'
                                  src={memberIcon}
                                  alt='avatar'
                                />
                              )}
                              <p>{`${(
                                data.first_name +
                                " " +
                                data.last_name
                              ).slice(0, 17)}${(data.first_name + " " + data.last_name)
                                .length > 17
                                ? "..."
                                : ""
                                }`}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='arrowIcon mx-4'>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                  <div className='memberInfos assignBox tableImage'>
                    {spaceImage ? (
                      <img
                        src={`${API}/${spaceImage}`}
                        alt='avatar'
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <img src={spaceIcon} alt='avatar' />
                    )}
                    <div>
                      <p>{name}</p>
                      <span>{currencyIcon} {rate}</span>
                    </div>
                  </div>
                </div>
                {memberError && <p className="text-danger mt-2">Please select a member</p>}
              </Col>
              <Col md={12}>
                <div className='assignName'>
                  <div className='generateInvoice my-0 d-flex align-items-center'>
                    <h5 className='mb-0'>Invoice Due Today?</h5>
                    <div className='authToggle mt-0'>
                      {authValue === true ? (
                        <label className='switch'>
                          <input
                            type='checkbox'
                            onClick={authClick}
                            defaultChecked
                          />
                          <span className='slider round'></span>
                        </label>
                      ) : (
                        <label className='switch'>
                          <input type='checkbox' onClick={authClick} />
                          <span className='slider round'></span>
                        </label>
                      )}
                    </div>
                  </div>
                  {authValue ? (
                    <>
                      <div className='generateInvoice mt-4 mb-0'>
                        <h6>Amount Owed Today</h6>
                        <div className='memberInput amount'>
                          <span>{currencyIcon}</span>
                          <input
                            type='text'
                            placeholder='Rate'
                            value={discountAmount}
                            onChange={(e) => setDiscountAmount(e.target.value)}
                            className='form-control'
                          />
                          <button className='calculate'>
                            (Automatically Prorated)
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className='invoiceSave mt-4'>
                  <button type='submit' onClick={spaceBooking}>
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>
    </>
  );
};

export default AssignMember;
