import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../Component/ConfirmationModal/ConfirmationModal';
import ReactQuill from 'react-quill';
import { getTourAgreement, tourAgreement } from '../../api/agreement';
import { v4 as uuidv4 } from 'uuid';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';



const Tours = () => {
  const [agreementId, setAgreementId] = useState('')
  const [confirmationShow, setConfirmationShow] = useState(false)
  const [content, setContent] = useState('');
  const [agreement, setAgreement] = useState(false);

  const [schedule, setSchedule] = useState({
    SUN: { isAvailable: false, timeSlot: { start: '1', end: '2' } },
    MON: { isAvailable: false, timeSlot: { start: '1', end: '2' } },
    TUE: { isAvailable: false, timeSlot: { start: '1', end: '2' } },
    WED: { isAvailable: false, timeSlot: { start: '1', end: '2' } },
    THU: { isAvailable: false, timeSlot: { start: '1', end: '2' } },
    FRI: { isAvailable: false, timeSlot: { start: '1', end: '2' } },
    SAT: { isAvailable: false, timeSlot: { start: '1', end: '2' } },
  });


  useEffect(() => {
    getTourAgreement().then((data) => {
      if (data.data.tour_email) {
        setContent(data.data.tour_email)
        setAgreement(true);

        const dayMappings = {
          sun: "SUN",
          mon: "MON",
          tue: "TUE",
          wed: "WED",
          thu: "THU",
          fri: "FRI",
          sat: "SAT",
        };

        const updatedSchedule: any = { ...schedule };

        Object.entries(dayMappings).forEach(([dbKey, stateKey]) => {
          const timeslot = data.data[dbKey];
          if (timeslot) {
            const [start, end] = timeslot.split("-");
            updatedSchedule[stateKey] = {
              isAvailable: true,
              timeSlot: { start, end },
            };
          }
        });
        setSchedule(updatedSchedule);
      }
      setAgreementId(data.data.id)
    }).catch((err) => { console.log(err) });
  }, [])

  var modules: any = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: [] },
      ],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color',
          ],
        },
      ],
    ],
  }

  var formats: any = [
    'header',
    'height',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'bullet',
    'indent',
    'link',
    'align',
    'size',
  ]
  const handleProcedureContentChange = (content: string) => {
    setContent(content)
  }

  const agreementClick = () => {
    setAgreement(!agreement)
  }


  const hourOptions = Array.from({ length: 24 }, (_, i) => {
    const hour = i;
    const ampm = hour >= 12 ? ' PM' : ' AM';
    const displayHour = hour % 12 || 12;
    return {
      value: hour.toString(),
      label: `${displayHour}:00${ampm}`
    };
  });

  const convertTo12HourFormat = (hour: number): string => {
    if (hour === 0 || hour > 24) {
      throw new Error("Invalid hour value");
    }
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12; // Convert to 12-hour format
    return `${formattedHour}:00 ${period}`;
  };

  const handleCheckboxChange = (day: string) => {
    setSchedule((prev: any) => ({
      ...prev,
      [day]: {
        ...prev[day],
        isAvailable: !prev[day].isAvailable,
      }
    }));
  };


  const handleSelect = (eventKey: any) => {
    const [value, field, day] = eventKey.split(" ");
    setSchedule((prev: any) => ({
      ...prev,
      [day]: {
        ...prev[day],
        timeSlot: {
          ...prev[day].timeSlot,
          [field]: value,
        }
      }
    }));
  };


  const tourAdd = () => {
    let agreementInfo = {
      id: agreementId ? agreementId : uuidv4(),
      tour_email: content,
      schedule: schedule
    }
    if (agreement) {
      tourAgreement(agreementInfo).then((data) => {
        showNotifications(TOAST_TYPE.success, data.message)
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setConfirmationShow(false)
      })
    }
    else {
      showNotifications(TOAST_TYPE.error, "Tour Confirmation text")
    }

  }

  return (
    <>
      <div>
        <div className='companyProfile'>
          <div className='profileHeading'>
            <h6>Tours</h6>
            <p>Customize your Tours settings.</p>
          </div>
          <div className='profileSave'>
            <button className='cancel'>Cancel</button>
            <button className='save' onClick={() => setConfirmationShow(true)}>Save</button>
          </div>
        </div>
        {/* Tour time */}
        <div className="companyName">
          <p>Days</p>
          <div className='rightFileSetting col-8'>
            <p>Time Slots</p>
          </div>
        </div>
        {Object.entries(schedule).map(([day, { isAvailable, timeSlot }]: any) => (
          <div key={day} className="companyName">
            <div className="tourDays">
              <label className='tableCheckBox d-flex align-items-center'>
                <div className='contactCheck'>
                  <input type='checkbox' checked={isAvailable} onChange={() => handleCheckboxChange(day)} />
                  <span className='checkmark'></span>
                </div>
                <p>
                  {day}
                </p>
              </label>
            </div>

            {isAvailable ? (
              <div className="rightFileSetting col-8">
                <div className="memberInput w-100">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle variant="" className="custom-toggle" style={{ textTransform: 'capitalize' }}>
                      {convertTo12HourFormat(timeSlot.start)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {hourOptions.map(({ value, label }) => (
                        <Dropdown.Item key={value} eventKey={`${value} ${`start`} ${day}`}>{label}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <button>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
                <p className='d-flex align-items-center mb-0 mr-2 ml-2'>To</p>
                <div className="memberInput w-100">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle variant="" className="custom-toggle" style={{ textTransform: 'capitalize' }}>
                      {convertTo12HourFormat(timeSlot.end)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {hourOptions.map(({ value, label }) => (
                        <Dropdown.Item key={value} eventKey={`${value} ${`end`} ${day}`}>{label}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <button>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
              </div>
            ) : (
              <div className="rightFileSetting col-8"><span className="unavailableText">Unavailable</span></div>
            )}
          </div>
        ))}
        {/* Tour time */}

        <div className="resourceDescription mt-4">
          <div className="generateInvoice">
            <h5 className='mb-0'>Add custom instructions to the Tour Confirmation email?</h5>
            <div className="authToggle mt-0">
              {agreement === true ?
                <label className="switch">
                  <input type="checkbox" onClick={agreementClick} defaultChecked />
                  <span className="slider round"></span>
                </label> :
                <label className="switch">
                  <input type="checkbox" onClick={agreementClick} />
                  <span className="slider round"></span>
                </label>}
            </div>
          </div>
          {agreement === true ? <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            placeholder='Enter a description...'
            onChange={handleProcedureContentChange}
            value={content}
          /> : ""}

        </div>
      </div>
      <ConfirmationModal
        ConfirmationShow={confirmationShow}
        afterConfirmationApi={tourAdd}
        handleConfirmationClose={() => setConfirmationShow(false)}
      />
    </>
  )
}

export default Tours