import React, { useState, useEffect } from "react";
import { Dropdown, Table } from "react-bootstrap";
import "./Spaces.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Component/Layout/Layout";
import { DESKIE_API as API } from "../../config";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import AddSpace from "../../Component/AddSpace/AddSpace";
import filter from "../../Assets/Images/icon/filter-lines.svg";
import { getSpacesList } from "../../api/spaces";
import editPen from "../../Assets/Images/icon/edit-01.svg";
import EditSpaces from "../../Component/ViewSpaces/EditSpaces";
import AssignMember from "../../Component/AssignMember/AssignMember";
import { separateComma, useFetchCurrency } from "../../CommonFunction/Function";
import blankUser from "../../Assets/Images/icon/blank-profile.jpg";
import memberAvatar from "../../Assets/Images/icon/memberAvatar.svg";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import Pagination from "../../Component/Pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";
import dots from "../../Assets/Images/icon/dots-vertical_black.svg";

const Spaces = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [assignShow, setAssignShow] = useState(false);
  const handleAssignClose = () => setAssignShow(false);
  const [spaces, setSpaces] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [spacesId, setSpacesId] = useState("");
  const [spaceId, setSpaceId] = useState("");
  const [updateShow, setUpdateShow] = useState(false);
  const handleUpdateClose = () => setUpdateShow(false);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [totalValue, setTotalValue] = useState<any>();
  const [limitValue, setLimitValue] = useState<any>();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState<number>(10);
  const pageCount = Math.ceil(totalValue / limitValue);
  const [prevButton, setPrevButton] = useState<boolean>(false);
  const [nextButton, setNextButton] = useState<boolean>(false);
  const [pageValue, setPageValue] = useState<number>();
  const { currencyIcon, fetchCurrency } = useFetchCurrency();

  useEffect(() => {
    fetchCurrency();
    getSpacesList(limit, page, searchTerm)
      .then((data) => {
        setSpaces(data && data.spaces);
        setTotalValue(data && data.total);
        setLimitValue(data && data.limit);
        setPageValue(data && data.page);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    show,
    page,
    totalValue,
    limitValue,
    limit,
    updateShow,
    assignShow,
    searchTerm,
  ]);

  useEffect(() => {
    if (pageCount > 1) {
      setPrevButton(true);
    }
    if (page === 1) {
      setPrevButton(false);
    }
    // next button
    if (pageCount > 1) {
      setNextButton(true);
    }
    if (pageCount === page) {
      setNextButton(false);
    }
  }, [pageCount, page]);

  const showResult = (value: number) => {
    setPage(1);
    setLimit(value);
  };

  // view
  const spacesView = (spacesId: string) => {
    setSpacesId(spacesId);
    return navigate(`${spacesId}`);
  };

  // update spaces
  const spacesUpdate = (spacesId: string) => {
    setSpacesId(spacesId);
    setUpdateShow(true);
  };

  // search
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const assignMembers = (spacesId: string) => {
    setAssignShow(true);
    setSpaceId(spacesId);
  };

  const nextPage = () => {
    setPage(page + 1);
    setNextButton(true);
  };

  const prevPage = () => {
    setPage(page - 1);
  };

  const handleSort = (columnName: string) => {
    if (sortBy === columnName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnName);
      setSortOrder("asc");
    }
  };

  const sortedAssets = [...spaces].sort((a: any, b: any) => {
    if (!sortBy) return 0;

    let comparison = 0;

    switch (sortBy) {
      case "name":
        comparison = a.name.localeCompare(b.name);
        break;
      case "status":
        const aHasImage = a.member_images && a.member_images.length > 0;
        const bHasImage = b.member_images && b.member_images.length > 0;

        if (aHasImage && !bHasImage) {
          comparison = 1;
        } else if (!aHasImage && bHasImage) {
          comparison = -1;
        } else if (aHasImage && bHasImage) {
          comparison = a.member_images.length - b.member_images.length;
        } else {
          comparison = 0;
        }
        break;
      case "type":
        comparison = a.tag.localeCompare(b.tag);
        break;
      case "rate":
        comparison = a.rate - b.rate;
        break;
      case "member_images":
        const aImage = a.member_images && a.member_images.length > 0;
        const bImage = b.member_images && b.member_images.length > 0;

        if (aImage && !bImage) {
          comparison = 1;
        } else if (!aImage && bImage) {
          comparison = -1;
        } else if (aImage && bImage) {
          comparison = a.member_images.length - b.member_images.length;
        } else {
          comparison = 0;
        }
        break;
      default:
        return 0;
    }

    return comparison * (sortOrder === "asc" ? 1 : -1);
  });

  return (
    <>
      <Layout>
        <div className='mainContent'>
          <div className='memberBox'>
            <div className='topLine'>
              <div className='tableHeading'>
                <h6>All Assets</h6>
              </div>
              <div className='memberSearch'>
                <div className='searchInput'>
                  <input
                    type='text'
                    placeholder='Search assets'
                    onChange={handleInputChange}
                    className='form-control'
                  />
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <button className='filterBtn'>
                  <img className='mr-2' src={filter} alt='filter' /> Filter
                </button>
                <button onClick={handleShow}>
                  <FontAwesomeIcon icon={faPlus} /> Add Asset
                </button>
              </div>
            </div>

            <div className='spaceList assetsPc'>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>
                      <label className='tableCheckBox'>
                        <div className='contactCheck'>
                          <input type='checkbox' name='agreement' />
                          <span className='checkmark'></span>
                        </div>
                      </label>
                    </th>
                    <th></th>
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("name")}
                    >
                      Name{" "}
                      {sortBy === "name" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("type")}
                    >
                      Type{" "}
                      {sortBy === "type" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("rate")}
                    >
                      Rate{" "}
                      {sortBy === "rate" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("status")}
                    >
                      Status{" "}
                      {sortBy === "status" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    <th
                      className='sortArrow'
                      onClick={() => handleSort("member_images")}
                    >
                      Assignment{" "}
                      {sortBy === "member_images" ? (
                        <>
                          {sortOrder === "asc" ? (
                            <FontAwesomeIcon icon={faArrowUp} />
                          ) : (
                            <FontAwesomeIcon icon={faArrowDown} />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAssets &&
                    sortedAssets.map((data: any, index) => (
                      <tr key={`refer` + index}>
                        <td>
                          <label className='tableCheckBox'>
                            <div className='contactCheck'>
                              <input type='checkbox' name='agreement' />
                              <span className='checkmark'></span>
                            </div>
                          </label>
                        </td>
                        <td>
                          <div
                            className='tableImage justify-content-center'
                            style={{ cursor: "pointer" }}
                            onClick={() => spacesView(data.id)}
                          >
                            {data.space_image ? (
                              <img
                                src={`${API}/${data.space_image}`}
                                alt='avatar'
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={spaceAvatar}
                                alt='avatar'
                                style={{ borderRadius: "50%" }}
                              />
                            )}{" "}
                          </div>
                        </td>
                        <td className='tableLink'>
                          <Link to={`${data.id}`}>{data.name}</Link>
                        </td>
                        <td className='deskType'>
                          {data.tag === "private" ? (
                            <span className='private'>Private Office</span>
                          ) : (
                            ""
                          )}
                          {data.tag === "dedicated" ? (
                            <span className='dedicated'>Dedicated Desk</span>
                          ) : (
                            ""
                          )}
                          {data.tag === "flex" ? (
                            <span className='flex'>Flex</span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className='rate'>
                          {currencyIcon} {parseFloat(data.rate).toFixed(2)} <small>/mo</small>
                        </td>
                        <td className='status'>
                          {data.tag === "flex" ? (
                            <span className='available'>Available</span>
                          ) : (
                            <>
                              {data.booked_member_id ? (
                                <span className='unavailable'>Unavailable</span>
                              ) : (
                                <span className='available'>Available</span>
                              )}
                            </>
                          )}
                        </td>
                        <td className='tableAction'>
                          {data.member_images ? (
                            <>
                              <div className='memberSpacesList'>
                                {data.member_images &&
                                  separateComma(data.member_images).map(
                                    (member: any, i: number) => (
                                      <div key={`memberImage` + i}>
                                        {member === "imgNull" ? (
                                          <img
                                            className='avatar-icon36 default'
                                            alt=''
                                            src={memberAvatar}
                                          />
                                        ) : (
                                          <img
                                            className='avatar-icon36'
                                            alt=''
                                            src={`${API}/${member}`}
                                          />
                                        )}
                                      </div>
                                    )
                                  )}
                                <div
                                  className='plusMember'
                                  onClick={() => assignMembers(data.id)}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {data.booked_member_id && !data.member_images ? (
                                <div className='memberSpacesList'>
                                  <img
                                    className='avatar-icon36 default'
                                    alt=''
                                    src={memberAvatar}
                                  />
                                  <div
                                    className='plusMember'
                                    onClick={() => assignMembers(data.id)}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </div>
                                </div>
                              ) : (
                                <button
                                  className='btn assign'
                                  onClick={() => assignMembers(data.id)}
                                >
                                  Assign
                                </button>
                              )}
                            </>
                          )}
                        </td>
                        <td className='tableAction'>
                          <button
                            className='btn view'
                            onClick={() => spacesView(data.id)}
                          >
                            <FontAwesomeIcon icon={faEye as any} />
                          </button>
                          <button
                            className='btn edit'
                            onClick={() => spacesUpdate(data.id)}
                          >
                            <img src={editPen} alt='edit' />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination
                page={page}
                paginationTitle='items'
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                prevButton={prevButton}
                nextButton={nextButton}
                pageValue={pageValue}
                totalValue={totalValue}
                prevPage={prevPage}
                nextPage={nextPage}
                allRequestList={spaces}
              />
            </div>

            <div className="mobileMember">
              <div className="mobileFilesHeader mb-3">
                <div className='searchInput'>
                  <input type="text" placeholder='Search assets' onChange={handleInputChange} className='form-control' />
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <button className='uploadIcon' onClick={handleShow}><FontAwesomeIcon icon={faPlus} /></button>
              </div>
              {sortedAssets.map((data: any, index) =>
                <div className='bookingPerson' style={{ flexDirection: "column", alignItems: "start", gap: 16, }} >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <div className='tableImage justify-content-center flex-grow-0' style={{ cursor: "pointer" }} >
                        {data.space_image ? (
                          <img src={`${API}/${data.space_image}`} alt='avatar' style={{ objectFit: "cover", borderRadius: "50%", }} />
                        ) : (
                          <img src={spaceAvatar} alt='avatar' style={{ borderRadius: "50%" }} />
                        )}
                      </div>
                      <div className='tableLink'>
                        <Link to={`${data.id}`}>
                          {data.name}
                        </Link>
                        <p> {data.tag === "private" ? (
                          <span className='private'>Private Office</span>
                        ) : (
                          ""
                        )}
                          {data.tag === "dedicated" ? (
                            <span className='dedicated'>Dedicated Desk</span>
                          ) : (
                            ""
                          )}
                          {data.tag === "flex" ? (
                            <span className='flex'>Flex</span>
                          ) : (
                            ""
                          )}</p>
                      </div>
                    </div>
                    {/* <img src={dots} alt="dots" /> */}
                    <div className="filesDropdown flex-grow-0">
                      <div className="trashPost">
                        <Dropdown className='p-0'>
                          <Dropdown.Toggle id="dropdown-basic" className='custom-dropdown-toggle p-0'>
                            <img className="line-chart-up-04-icon" alt="" src={dots} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='postDelete'>
                            <Dropdown.Item className='custom-dropdown-toggle'><FontAwesomeIcon icon={faEye as any} /> View</Dropdown.Item>
                            <Dropdown.Item className='custom-dropdown-toggle'><img src={editPen} alt="edit" /> Edit</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div className='resourceType rscT memberPhone'>
                      {currencyIcon} {parseFloat(data.rate).toFixed(2)} <small>/mo</small>
                    </div>
                    <div className='editBtn serc'>
                      <button onClick={() => assignMembers(data.id)} style={{ width: "100%", height: "100%", paddingTop: "4px", paddingBottom: "4px" }}>
                        Assign
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="mobilePagination">
                <Pagination page={page} paginationTitle="items" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={spaces} />
              </div>
            </div>

            <AddSpace show={show} setShow={setShow} handleClose={handleClose} />
            <EditSpaces
              spacesId={spacesId}
              updateShow={updateShow}
              setUpdateShow={setUpdateShow}
              handleUpdateClose={handleUpdateClose}
            />
            <AssignMember
              spaceId={spaceId}
              assignShow={assignShow}
              setAssignShow={setAssignShow}
              handleAssignClose={handleAssignClose}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Spaces;
